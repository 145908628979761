body {
  font-family: Montserrat;
}
.display-1 {
  font-family: 'Montserrat', sans-serif;
  font-size: 3rem;
}
.display-1 > .mbr-iconfont {
  font-size: 4.8rem;
}
.display-2 {
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 2rem;
}
.display-2 > .mbr-iconfont {
  font-size: 3.2rem;
}
.display-4 {
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
}
.display-4 > .mbr-iconfont {
  font-size: 1.6rem;
}
.display-5 {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.2rem;
}
.display-5 > .mbr-iconfont {
  font-size: 1.92rem;
}
.display-7 {
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
}
.display-7 > .mbr-iconfont {
  font-size: 1.6rem;
}
/* ---- Fluid typography for mobile devices ---- */
/* 1.4 - font scale ratio ( bootstrap == 1.42857 ) */
/* 100vw - current viewport width */
/* (48 - 20)  48 == 48rem == 768px, 20 == 20rem == 320px(minimal supported viewport) */
/* 0.65 - min scale variable, may vary */
@media (max-width: 768px) {
  .display-1 {
    font-size: 2.4rem;
    font-size: calc( 1.7rem + (3 - 1.7) * ((100vw - 20rem) / (48 - 20)));
    line-height: calc( 1.4 * (1.7rem + (3 - 1.7) * ((100vw - 20rem) / (48 - 20))));
  }
  .display-2 {
    font-size: 1.6rem;
    font-size: calc( 1.35rem + (2 - 1.35) * ((100vw - 20rem) / (48 - 20)));
    line-height: calc( 1.4 * (1.35rem + (2 - 1.35) * ((100vw - 20rem) / (48 - 20))));
  }
  .display-4 {
    font-size: 0.8rem;
    font-size: calc( 1rem + (1 - 1) * ((100vw - 20rem) / (48 - 20)));
    line-height: calc( 1.4 * (1rem + (1 - 1) * ((100vw - 20rem) / (48 - 20))));
  }
  .display-5 {
    font-size: 0.96rem;
    font-size: calc( 1.07rem + (1.2 - 1.07) * ((100vw - 20rem) / (48 - 20)));
    line-height: calc( 1.4 * (1.07rem + (1.2 - 1.07) * ((100vw - 20rem) / (48 - 20))));
  }
}
/* Buttons */
.btn {
  padding: 0.8rem 2rem;
  border-radius: 0.25rem;
}
.btn-sm {
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
}
.btn-md {
  padding: 0.8rem 2rem;
  border-radius: 0.25rem;
}
.btn-lg {
  padding: 0.8rem 2rem;
  border-radius: 0.25rem;
}
.bg-primary {
  background-color: #ff4f7b !important;
}
.bg-success {
  background-color: #1dc9cb !important;
}
.bg-info {
  background-color: #188ef4 !important;
}
.bg-warning {
  background-color: #c1c1c1 !important;
}
.bg-danger {
  background-color: #000f25 !important;
}
.btn-primary,
.btn-primary:active,
.btn-primary.active {
  background-color: #ff4f7b !important;
  border-color: #ff4f7b !important;
  transition: all .5s;
  color: #ffffff !important;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary.focus {
  transition: all .5s;
  color: #ffffff !important;
  background-color: #ff0242 !important;
  border-color: #ff0242 !important;
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #ffffff !important;
  background-color: #ff0242 !important;
  border-color: #ff0242 !important;
}
.btn-secondary,
.btn-secondary:active,
.btn-secondary.active {
  background-color: #2bdc9d !important;
  border-color: #2bdc9d !important;
  transition: all .5s;
  color: #ffffff !important;
}
.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary.focus {
  transition: all .5s;
  color: #ffffff !important;
  background-color: #1aa071 !important;
  border-color: #1aa071 !important;
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #ffffff !important;
  background-color: #1aa071 !important;
  border-color: #1aa071 !important;
}
.btn-info,
.btn-info:active,
.btn-info.active {
  background-color: #188ef4 !important;
  border-color: #188ef4 !important;
  transition: all .5s;
  color: #ffffff !important;
}
.btn-info:hover,
.btn-info:focus,
.btn-info.focus {
  transition: all .5s;
  color: #ffffff !important;
  background-color: #0966b7 !important;
  border-color: #0966b7 !important;
}
.btn-info.disabled,
.btn-info:disabled {
  color: #ffffff !important;
  background-color: #0966b7 !important;
  border-color: #0966b7 !important;
}
.btn-success,
.btn-success:active,
.btn-success.active {
  background-color: #1dc9cb !important;
  border-color: #1dc9cb !important;
  transition: all .5s;
  color: #ffffff !important;
}
.btn-success:hover,
.btn-success:focus,
.btn-success.focus {
  transition: all .5s;
  color: #ffffff !important;
  background-color: #138788 !important;
  border-color: #138788 !important;
}
.btn-success.disabled,
.btn-success:disabled {
  color: #ffffff !important;
  background-color: #138788 !important;
  border-color: #138788 !important;
}
.btn-warning,
.btn-warning:active,
.btn-warning.active {
  background-color: #c1c1c1 !important;
  border-color: #c1c1c1 !important;
  transition: all .5s;
  color: #ffffff !important;
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning.focus {
  transition: all .5s;
  color: #ffffff !important;
  background-color: #9b9b9b !important;
  border-color: #9b9b9b !important;
}
.btn-warning.disabled,
.btn-warning:disabled {
  color: #ffffff !important;
  background-color: #9b9b9b !important;
  border-color: #9b9b9b !important;
}
.btn-danger,
.btn-danger:active,
.btn-danger.active {
  background-color: #000f25 !important;
  border-color: #000f25 !important;
  transition: all .5s;
  color: #ffffff !important;
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger.focus {
  transition: all .5s;
  color: #ffffff !important;
  background-color: #000000 !important;
  border-color: #000000 !important;
}
.btn-danger.disabled,
.btn-danger:disabled {
  color: #ffffff !important;
  background-color: #000000 !important;
  border-color: #000000 !important;
}
.btn-white {
  color: #333333 !important;
}
.btn-white,
.btn-white:active,
.btn-white.active {
  background-color: #ffffff !important;
  border-color: #ffffff !important;
  transition: all .5s;
  color: #808080 !important;
}
.btn-white:hover,
.btn-white:focus,
.btn-white.focus {
  transition: all .5s;
  color: #808080 !important;
  background-color: #d9d9d9 !important;
  border-color: #d9d9d9 !important;
}
.btn-white.disabled,
.btn-white:disabled {
  color: #808080 !important;
  background-color: #d9d9d9 !important;
  border-color: #d9d9d9 !important;
}
.btn-black,
.btn-black:active,
.btn-black.active {
  background-color: #333333 !important;
  border-color: #333333 !important;
  transition: all .5s;
  color: #ffffff !important;
}
.btn-black:hover,
.btn-black:focus,
.btn-black.focus {
  transition: all .5s;
  color: #ffffff !important;
  background-color: #0d0d0d !important;
  border-color: #0d0d0d !important;
}
.btn-black.disabled,
.btn-black:disabled {
  color: #ffffff !important;
  background-color: #0d0d0d !important;
  border-color: #0d0d0d !important;
}
.btn-primary-outline,
.btn-primary-outline:active,
.btn-primary-outline.active {
  background: none !important;
  border-color: #e8003a !important;
  color: #e8003a !important;
}
.btn-primary-outline:hover,
.btn-primary-outline:focus,
.btn-primary-outline.focus {
  color: #ffffff !important;
  background-color: #ff4f7b !important;
  border-color: #ff4f7b !important;
}
.btn-primary-outline.disabled,
.btn-primary-outline:disabled {
  color: #ffffff !important;
  background-color: #ff4f7b !important;
  border-color: #ff4f7b !important;
}
.btn-secondary-outline,
.btn-secondary-outline:active,
.btn-secondary-outline.active {
  background: none !important;
  border-color: #178a61 !important;
  color: #178a61 !important;
}
.btn-secondary-outline:hover,
.btn-secondary-outline:focus,
.btn-secondary-outline.focus {
  color: #ffffff !important;
  background-color: #2bdc9d !important;
  border-color: #2bdc9d !important;
}
.btn-secondary-outline.disabled,
.btn-secondary-outline:disabled {
  color: #ffffff !important;
  background-color: #2bdc9d !important;
  border-color: #2bdc9d !important;
}
.btn-info-outline,
.btn-info-outline:active,
.btn-info-outline.active {
  background: none !important;
  border-color: #08589e !important;
  color: #08589e !important;
}
.btn-info-outline:hover,
.btn-info-outline:focus,
.btn-info-outline.focus {
  color: #ffffff !important;
  background-color: #188ef4 !important;
  border-color: #188ef4 !important;
}
.btn-info-outline.disabled,
.btn-info-outline:disabled {
  color: #ffffff !important;
  background-color: #188ef4 !important;
  border-color: #188ef4 !important;
}
.btn-success-outline,
.btn-success-outline:active,
.btn-success-outline.active {
  background: none !important;
  border-color: #107172 !important;
  color: #107172 !important;
}
.btn-success-outline:hover,
.btn-success-outline:focus,
.btn-success-outline.focus {
  color: #ffffff !important;
  background-color: #1dc9cb !important;
  border-color: #1dc9cb !important;
}
.btn-success-outline.disabled,
.btn-success-outline:disabled {
  color: #ffffff !important;
  background-color: #1dc9cb !important;
  border-color: #1dc9cb !important;
}
.btn-warning-outline,
.btn-warning-outline:active,
.btn-warning-outline.active {
  background: none !important;
  border-color: #8e8e8e !important;
  color: #8e8e8e !important;
}
.btn-warning-outline:hover,
.btn-warning-outline:focus,
.btn-warning-outline.focus {
  color: #ffffff !important;
  background-color: #c1c1c1 !important;
  border-color: #c1c1c1 !important;
}
.btn-warning-outline.disabled,
.btn-warning-outline:disabled {
  color: #ffffff !important;
  background-color: #c1c1c1 !important;
  border-color: #c1c1c1 !important;
}
.btn-danger-outline,
.btn-danger-outline:active,
.btn-danger-outline.active {
  background: none !important;
  border-color: #000000 !important;
  color: #000000 !important;
}
.btn-danger-outline:hover,
.btn-danger-outline:focus,
.btn-danger-outline.focus {
  color: #ffffff !important;
  background-color: #000f25 !important;
  border-color: #000f25 !important;
}
.btn-danger-outline.disabled,
.btn-danger-outline:disabled {
  color: #ffffff !important;
  background-color: #000f25 !important;
  border-color: #000f25 !important;
}
.btn-black-outline,
.btn-black-outline:active,
.btn-black-outline.active {
  background: none !important;
  border-color: #000000 !important;
  color: #000000 !important;
}
.btn-black-outline:hover,
.btn-black-outline:focus,
.btn-black-outline.focus {
  color: #ffffff !important;
  background-color: #333333 !important;
  border-color: #333333 !important;
}
.btn-black-outline.disabled,
.btn-black-outline:disabled {
  color: #ffffff !important;
  background-color: #333333 !important;
  border-color: #333333 !important;
}
.btn-white-outline,
.btn-white-outline:active,
.btn-white-outline.active {
  background: none;
  border-color: #ffffff;
  color: #ffffff;
}
.btn-white-outline:hover,
.btn-white-outline:focus,
.btn-white-outline.focus {
  color: #333333;
  background-color: #ffffff;
  border-color: #ffffff;
}
.text-primary {
  color: #ff4f7b !important;
}
.text-secondary {
  color: #2bdc9d !important;
}
.text-success {
  color: #1dc9cb !important;
}
.text-info {
  color: #188ef4 !important;
}
.text-warning {
  color: #c1c1c1 !important;
}
.text-danger {
  color: #000f25 !important;
}
.text-white {
  color: #ffffff !important;
}
.text-black {
  color: #000000 !important;
}
a.text-primary:hover,
a.text-primary:focus {
  color: #e8003a !important;
}
a.text-secondary:hover,
a.text-secondary:focus {
  color: #178a61 !important;
}
a.text-success:hover,
a.text-success:focus {
  color: #107172 !important;
}
a.text-info:hover,
a.text-info:focus {
  color: #08589e !important;
}
a.text-warning:hover,
a.text-warning:focus {
  color: #8e8e8e !important;
}
a.text-danger:hover,
a.text-danger:focus {
  color: #000000 !important;
}
a.text-white:hover,
a.text-white:focus {
  color: #b3b3b3 !important;
}
a.text-black:hover,
a.text-black:focus {
  color: #4d4d4d !important;
}
.alert-success {
  background-color: #1dc9cb;
}
.alert-info {
  background-color: #188ef4;
}
.alert-warning {
  background-color: #c1c1c1;
}
.alert-danger {
  background-color: #000f25;
}
.mbr-section-btn a.btn {
  border-radius: 100px;
}
.mbr-section-btn a.btn:hover,
.mbr-section-btn a.btn:focus {
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2) !important;
}
.mbr-gallery-filter li a {
  border-radius: 100px !important;
}
.mbr-gallery-filter li.active .btn {
  background-color: #ff4f7b;
  border-color: #ff4f7b;
  color: #ffffff;
}
.mbr-gallery-filter li.active .btn:focus {
  box-shadow: none;
}
.nav-tabs .nav-link {
  border-radius: 100px !important;
}
a,
a:hover {
  color: #ff4f7b;
}
.mbr-plan-header.bg-primary .mbr-plan-subtitle,
.mbr-plan-header.bg-primary .mbr-plan-price-desc {
  color: #ffffff;
}
.mbr-plan-header.bg-success .mbr-plan-subtitle,
.mbr-plan-header.bg-success .mbr-plan-price-desc {
  color: #bef5f6;
}
.mbr-plan-header.bg-info .mbr-plan-subtitle,
.mbr-plan-header.bg-info .mbr-plan-price-desc {
  color: #dbedfd;
}
.mbr-plan-header.bg-warning .mbr-plan-subtitle,
.mbr-plan-header.bg-warning .mbr-plan-price-desc {
  color: #ffffff;
}
.mbr-plan-header.bg-danger .mbr-plan-subtitle,
.mbr-plan-header.bg-danger .mbr-plan-price-desc {
  color: #8bbaff;
}
/* Scroll to top button*/
#scrollToTop a {
  border-radius: 100px;
}
.mbr-arrow-up a {
  width: 61px;
  height: 61px;
}
#scrollToTop a {
  background: #04367c;
}
#scrollToTop a i:before {
  content: '';
  position: absolute;
  height: 40%;
  top: 25%;
  background: #ffffff;
  width: 3%;
  left: calc(50% - 1px);
}
#scrollToTop a i:after {
  content: '';
  position: absolute;
  display: block;
  border-top: 2px solid #ffffff;
  border-right: 2px solid #ffffff;
  width: 40%;
  height: 40%;
  left: 30%;
  bottom: 30%;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}
.mbr-arrow i {
  color: #04367c;
}
.form-control {
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
}
.form-control > .mbr-iconfont {
  font-size: 1.6rem;
}
blockquote {
  border-color: #ff4f7b;
}
/* Forms */
.mbr-form .btn {
  margin: .4rem 0;
}
.mbr-form .input-group-btn a.btn {
  border-radius: 100px !important;
}
.mbr-form .input-group-btn a.btn:hover {
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
}
.mbr-form .input-group-btn button[type="submit"] {
  border-radius: 100px !important;
}
.mbr-form .input-group-btn button[type="submit"]:hover {
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
}
@media (max-width: 767px) {
  .btn {
    font-size: .75rem !important;
  }
  .btn .mbr-iconfont {
    font-size: 1rem !important;
  }
}
/* Social block */
.btn-social {
  font-size: 20px;
  border-radius: 50%;
  padding: 0;
  width: 44px;
  height: 44px;
  line-height: 44px;
  text-align: center;
  position: relative;
  border: 2px solid #c0a375;
  border-color: #ff4f7b;
  color: #232323;
  cursor: pointer;
}
.btn-social i {
  top: 0;
  line-height: 44px;
  width: 44px;
}
.btn-social:hover {
  color: #fff;
  background: #ff4f7b;
}
.btn-social + .btn {
  margin-left: .1rem;
}
@media (min-width: 768px) {
  .rounded-left {
    border-top-left-radius: 100px !important;
    border-bottom-left-radius: 100px !important;
  }
  .rounded-left {
    border-top-left-radius: 100px !important;
    border-bottom-left-radius: 100px !important;
  }
}
.jq-selectbox li:hover,
.jq-selectbox li.selected {
  background-color: #ff4f7b;
  color: #ffffff;
}
.jq-number__spin {
  background-color: #ffffff;
}
.jq-selectbox .jq-selectbox__trigger-arrow,
.jq-number__spin.minus:after,
.jq-number__spin.plus:after {
  transition: 0.4s;
  border-top-color: currentColor;
  border-bottom-color: currentColor;
}
.jq-selectbox:hover .jq-selectbox__trigger-arrow,
.jq-number__spin.minus:hover:after,
.jq-number__spin.plus:hover:after {
  border-top-color: #ff4f7b;
  border-bottom-color: #ff4f7b;
}
.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_default,
.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_current,
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div.xdsoft_current {
  color: #ffffff !important;
  background-color: #ff4f7b !important;
  box-shadow: none !important;
}
.xdsoft_datetimepicker .xdsoft_calendar td:hover,
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div:hover {
  color: #000000 !important;
  background: #2bdc9d !important;
  box-shadow: none !important;
}
.lazy-bg {
  background-image: none !important;
}
.lazy-placeholder:not(section),
.lazy-none {
  display: block;
  position: relative;
  padding-bottom: 56.25%;
}
iframe.lazy-placeholder,
.lazy-placeholder:after {
  content: '';
  position: absolute;
  width: 200px;
  height: 200px;
  background: transparent no-repeat center;
  background-size: contain;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='32' height='32' viewBox='0 0 64 64' xmlns='http://www.w3.org/2000/svg' stroke='%23ff4f7b' %3e%3cg fill='none' fill-rule='evenodd'%3e%3cg transform='translate(16 16)' stroke-width='2'%3e%3ccircle stroke-opacity='.5' cx='16' cy='16' r='16'/%3e%3cpath d='M32 16c0-9.94-8.06-16-16-16'%3e%3canimateTransform attributeName='transform' type='rotate' from='0 16 16' to='360 16 16' dur='1s' repeatCount='indefinite'/%3e%3c/path%3e%3c/g%3e%3c/g%3e%3c/svg%3e");
}
section.lazy-placeholder:after {
  opacity: 0.5;
}
.cid-sz7j2vwKcW {
  padding-top: 90px;
  padding-bottom: 90px;
  background-color: #4284df;
}
.cid-sz7j2vwKcW h2,
.cid-sz7j2vwKcW h3,
.cid-sz7j2vwKcW h4,
.cid-sz7j2vwKcW p {
  color: #ffffff;
}
.cid-sz7j2vwKcW .mbr-overlay {
  z-index: -1;
}
.cid-sz7j2vwKcW .mbr-iconfont {
  font-size: 48px;
  padding-right: 1rem;
}
.cid-sz7j2vwKcW .form-control {
  box-shadow: none;
  margin-bottom: 15px;
  padding: 1.07em 1.07em;
  background-color: #f5f5f5;
  border: 1px solid #e8e8e8;
  color: #656565;
  line-height: 1.43;
  min-height: 3.5em;
  border-radius: .25rem;
}
.cid-sz7j2vwKcW .form-control:focus,
.cid-sz7j2vwKcW .form-control:hover {
  border: 1px solid #e8e8e8;
  background-color: #f5f5f5;
}
.cid-sz7j2vwKcW .form-group {
  margin-bottom: 1rem;
}
.cid-sz7j2vwKcW input::-webkit-input-placeholder,
.cid-sz7j2vwKcW textarea::-webkit-input-placeholder {
  color: #656565;
}
.cid-sz7j2vwKcW input:-moz-placeholder,
.cid-sz7j2vwKcW textarea:-moz-placeholder {
  color: #656565;
}
.cid-sz7j2vwKcW .jq-selectbox li,
.cid-sz7j2vwKcW .jq-selectbox li {
  background-color: #f5f5f5;
  color: #000000;
}
.cid-sz7j2vwKcW .jq-selectbox li:hover,
.cid-sz7j2vwKcW .jq-selectbox li.selected {
  background-color: #f5f5f5;
  color: #000000;
}
.cid-sz7j2vwKcW .jq-selectbox:hover .jq-selectbox__trigger-arrow {
  border-top-color: #f5f5f5;
}
.cid-sz7j2vwKcW .jq-selectbox .jq-selectbox__trigger-arrow {
  border-top-color: #f5f5f5;
}
.cid-sz7j2vwKcW .media-container-row {
  -webkit-align-items: center;
  align-items: center;
}
.cid-sz7j2vwKcW .mbr-text {
  color: #767676;
}
.cid-sz7j2vwKcW .form-block {
  padding: 3rem;
  position: relative;
}
.cid-sz7j2vwKcW .form-block .bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #2c1616;
  opacity: 0.5;
  z-index: 0;
}
.cid-sz7j2vwKcW .form-block .form-wrap {
  position: relative;
  z-index: 1;
}
.cid-sz7j2vwKcW a:not([href]):not([tabindex]) {
  color: #fff;
}
.cid-sz7j2vwKcW .multi-horizontal {
  flex-grow: 1;
  -webkit-flex-grow: 1;
  max-width: 100%;
}
.cid-sz7j2vwKcW .input-group-btn {
  display: block;
  text-align: center;
}
.cid-sz7j2vwKcW textarea.form-control {
  resize: none;
}
.cid-sz7j2vwKcW .media-container-row {
  -webkit-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
@media (min-width: 768px) {
  .cid-sz7j2vwKcW .container > .row > .col-md-6:first-child {
    padding-right: 30px;
  }
  .cid-sz7j2vwKcW .container > .row > .col-md-6:last-child {
    padding-left: 30px;
  }
}
@media (max-width: 991px) {
  .cid-sz7j2vwKcW .google-map {
    padding-bottom: 2rem;
  }
}
@media (max-width: 767px) {
  .cid-sz7j2vwKcW h2 {
    padding-top: 2rem;
  }
}
@media (max-width: 499px) {
  .cid-sz7j2vwKcW .block-content {
    padding: 0;
  }
  .cid-sz7j2vwKcW .form-block {
    padding: 1rem;
  }
}
.cid-sz74JbitiK {
  padding-top: 100px;
  padding-bottom: 60px;
  background: #a4b8c9;
}
.cid-sz74JbitiK .underline .line {
  width: 2rem;
  height: 1px;
  background: #ffffff;
  display: inline-block;
}
.cid-sz74JbitiK .main-head {
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  justify-content: center;
}
.cid-sz74JbitiK .first-table {
  margin: 0;
}
.cid-sz74JbitiK .first-table.two-items {
  margin-right: 15px;
}
.cid-sz74JbitiK .first-table .table-head-wrapper {
  background: #08579f;
}
.cid-sz74JbitiK .second-table {
  margin: 0;
  margin-left: 15px;
}
.cid-sz74JbitiK .second-table .table-head-wrapper {
  background: #098484;
}
.cid-sz74JbitiK .table {
  padding: 2rem;
  background: #ffffff;
  position: relative;
}
.cid-sz74JbitiK .table .table-wrapper .table-head-wrapper {
  margin: -2rem;
  margin-bottom: 0 !important;
  padding: 2rem;
  padding-bottom: 0 !important;
}
.cid-sz74JbitiK .table .table-wrapper .table-head-wrapper span {
  width: 100%;
  display: block;
}
.cid-sz74JbitiK .table .table-wrapper .table-purchase {
  padding-bottom: 2rem;
}
.cid-sz74JbitiK .table .table-wrapper .table-purchase .table-pricing {
  margin: 0;
  margin-top: .5rem !important;
}
.cid-sz74JbitiK .table .table-wrapper .list-group {
  padding-top: 2rem;
  list-style: none;
}
.cid-sz74JbitiK .table .table-wrapper .list-group li {
  padding: .4rem;
  border-bottom: 1px solid #f4f8fa;
}
.cid-sz74JbitiK .table .table-wrapper .list-group li .mbr-iconfont {
  font-size: 30px;
}
@media (max-width: 767px) {
  .cid-sz74JbitiK .first-table,
  .cid-sz74JbitiK .second-table {
    margin: 0 15px;
  }
  .cid-sz74JbitiK .first-table {
    margin-bottom: 1rem;
  }
}
@media (min-width: 768px) {
  .cid-sz74JbitiK .items-row {
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .cid-sz74JbitiK .first-table,
  .cid-sz74JbitiK .second-table {
    max-width: calc(50% - 15px);
  }
}
@media (max-width: 991px) {
  .cid-sz74JbitiK .main-head {
    padding-bottom: 2rem;
  }
}
.cid-sz74JbitiK .table-heading {
  text-align: center;
  color: #ffffff;
}
.cid-sz74JbitiK .pricing-value {
  text-align: center;
  color: #ffffff;
}
.cid-sz74JbitiK .table-pricing {
  text-align: center;
  color: #ffffff;
}
.cid-sz74JbitiK .main-title {
  color: #000000;
}
.cid-qKSj0s9f9b {
  padding-top: 120px;
  padding-bottom: 90px;
  background-color: #000f25;
}
@media (max-width: 767px) {
  .cid-qKSj0s9f9b .content {
    text-align: center;
  }
  .cid-qKSj0s9f9b .content > div:not(:last-child) {
    margin-bottom: 2rem;
  }
}
.cid-qKSj0s9f9b .logo-subtitle {
  color: #f4f8fa;
}
.cid-qKSj0s9f9b .media-wrap {
  padding-bottom: 2rem;
}
@media (max-width: 767px) {
  .cid-qKSj0s9f9b .media-wrap {
    margin-bottom: 1rem;
  }
}
.cid-qKSj0s9f9b .media-wrap .mbr-iconfont-logo {
  font-size: 7.5rem;
  color: #f36;
}
.cid-qKSj0s9f9b .media-wrap img {
  height: 6rem;
}
@media (max-width: 767px) {
  .cid-qKSj0s9f9b .footer-lower .copyright {
    margin-bottom: 1rem;
    text-align: center;
  }
}
.cid-qKSj0s9f9b .footer-lower hr {
  margin: 1rem 0;
  border-color: #efefef;
  opacity: .05;
}
.cid-qKSj0s9f9b .footer-lower .social-list {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
}
.cid-qKSj0s9f9b .footer-lower .social-list .mbr-iconfont-social {
  font-size: 1.3rem;
  color: #232323;
}
.cid-qKSj0s9f9b .footer-lower .social-list .soc-item {
  margin: 0 .5rem;
}
.cid-qKSj0s9f9b .footer-lower .social-list a {
  margin: 0;
  opacity: .5;
  -webkit-transition: .2s linear;
  transition: .2s linear;
}
.cid-qKSj0s9f9b .footer-lower .social-list a:hover {
  opacity: 1;
}
@media (max-width: 767px) {
  .cid-qKSj0s9f9b .footer-lower .social-list {
    justify-content: center;
    -webkit-justify-content: center;
  }
}
.cid-qKSj0s9f9b .list-item {
  display: flex;
}
.cid-qKSj0s9f9b .list-item .mbr-iconfont {
  padding-right: 1rem;
  font-size: 24px;
  color: #188ef4;
}
.cid-qKSj0s9f9b ul.list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.cid-qKSj0s9f9b ul.list li {
  display: flex;
  word-break: break-all;
}
.cid-qKSj0s9f9b ul.list li::before {
  font-family: MobiriseIcons !important;
  content: '\e966';
  padding-right: .5rem;
}
.cid-qKSj0s9f9b .mbr-text {
  color: #f4f8fa;
}
.cid-qKSj0s9f9b .column-title {
  color: #f4f8fa;
}
.cid-qKSj0s9f9b P {
  color: #f4f8fa;
}
.cid-sz756eI19K .dropdown-item:before {
  font-family: MobiriseIcons !important;
  content: '\e966';
  display: inline-block;
  width: 16px;
  position: absolute;
  right: 1rem;
  top: 30%;
  line-height: 1;
  font-size: inherit;
  vertical-align: middle;
  text-align: center;
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.cid-sz756eI19K .nav-item,
.cid-sz756eI19K .nav-link,
.cid-sz756eI19K .navbar-caption {
  font-weight: normal;
}
.cid-sz756eI19K .nav-item:focus,
.cid-sz756eI19K .nav-link:focus {
  outline: none;
}
@media (min-width: 992px) {
  .cid-sz756eI19K .dropdown-item:hover:before {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
.cid-sz756eI19K .dropdown .dropdown-menu .dropdown-item {
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  width: auto;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.cid-sz756eI19K .dropdown .dropdown-menu .dropdown-item::after {
  right: 1rem;
}
.cid-sz756eI19K .dropdown .dropdown-menu .dropdown-item:hover .mbr-iconfont:before {
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
}
.cid-sz756eI19K .dropdown .dropdown-menu .dropdown-item.dropdown-toggle:before {
  display: none;
}
.cid-sz756eI19K .dropdown .dropdown-menu .dropdown-item .mbr-iconfont {
  margin-left: -1.8rem;
  padding-right: 1rem;
  font-size: inherit;
}
.cid-sz756eI19K .dropdown .dropdown-menu .dropdown-item .mbr-iconfont:before {
  display: inline-block;
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.cid-sz756eI19K .collapsed .dropdown-menu .dropdown-item:before {
  display: none;
}
.cid-sz756eI19K .collapsed .dropdown .dropdown-menu .dropdown-item {
  padding: 0.235em 1.5em 0.235em 1.5em !important;
  transition: none;
  margin: 0 !important;
}
.cid-sz756eI19K .navbar {
  min-height: 77px;
  transition: all .3s;
  background: #1dc9cb;
  background: none;
}
.cid-sz756eI19K .navbar.opened {
  transition: all .3s;
  background: #1dc9cb !important;
}
.cid-sz756eI19K .navbar .dropdown-item {
  padding: .235rem 2.5rem .235rem 1.5rem;
}
.cid-sz756eI19K .navbar .navbar-collapse {
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  z-index: 1;
  display: none !important;
}
.cid-sz756eI19K .navbar.collapsed .nav-item .nav-link::before {
  display: none;
}
.cid-sz756eI19K .navbar.collapsed.opened .dropdown-menu {
  top: 0;
}
.cid-sz756eI19K .navbar.collapsed .dropdown-menu {
  background: transparent !important;
}
.cid-sz756eI19K .navbar.collapsed .dropdown-menu .dropdown-submenu {
  left: 0 !important;
}
.cid-sz756eI19K .navbar.collapsed .dropdown-menu .dropdown-toggle[data-toggle="dropdown-submenu"]:after {
  margin-left: .25rem;
  border-top: 0.35em solid;
  border-right: 0.35em solid transparent;
  border-left: 0.35em solid transparent;
  border-bottom: 0;
  top: 55%;
}
.cid-sz756eI19K .navbar.collapsed ul.navbar-nav li {
  margin: auto;
}
.cid-sz756eI19K .navbar.collapsed .dropdown-menu .dropdown-item {
  padding: .25rem 1.5rem;
  text-align: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.cid-sz756eI19K .navbar.collapsed .icons-menu {
  padding-left: 0;
  padding-top: .5rem;
  padding-bottom: .5rem;
}
@media (max-width: 991px) {
  .cid-sz756eI19K .navbar .nav-item .nav-link::before {
    display: none;
  }
  .cid-sz756eI19K .navbar.opened .dropdown-menu {
    top: 0;
  }
  .cid-sz756eI19K .navbar .dropdown-menu {
    background: transparent !important;
  }
  .cid-sz756eI19K .navbar .dropdown-menu .dropdown-submenu {
    left: 0 !important;
  }
  .cid-sz756eI19K .navbar .dropdown-menu .dropdown-toggle[data-toggle="dropdown-submenu"]:after {
    margin-left: .25rem;
    border-top: 0.35em solid;
    border-right: 0.35em solid transparent;
    border-left: 0.35em solid transparent;
    border-bottom: 0;
    top: 55%;
  }
  .cid-sz756eI19K .navbar .navbar-logo img {
    height: 3.8rem !important;
  }
  .cid-sz756eI19K .navbar ul.navbar-nav li {
    margin: auto;
  }
  .cid-sz756eI19K .navbar .dropdown-menu .dropdown-item {
    padding: .25rem 1.5rem !important;
    text-align: center;
    -webkit-justify-content: center;
    justify-content: center;
  }
  .cid-sz756eI19K .navbar .navbar-brand {
    -webkit-flex-shrink: initial;
    flex-shrink: initial;
    -webkit-flex-basis: auto;
    flex-basis: auto;
    word-break: break-word;
  }
  .cid-sz756eI19K .navbar .navbar-toggler {
    -webkit-flex-basis: auto;
    flex-basis: auto;
  }
  .cid-sz756eI19K .navbar .icons-menu {
    padding-left: 0;
    padding-top: .5rem;
    padding-bottom: .5rem;
  }
}
.cid-sz756eI19K .navbar.navbar-short {
  background: #1dc9cb !important;
  min-height: 60px;
}
.cid-sz756eI19K .navbar.navbar-short .navbar-logo img {
  height: 3rem !important;
}
.cid-sz756eI19K .navbar.navbar-short .navbar-brand {
  padding: 0;
}
.cid-sz756eI19K .navbar-brand {
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  -webkit-align-items: center;
  align-items: center;
  margin-right: 0;
  padding: 0;
  transition: all .3s;
  word-break: break-word;
  z-index: 1;
}
.cid-sz756eI19K .navbar-brand .navbar-caption {
  line-height: inherit !important;
}
.cid-sz756eI19K .navbar-brand .navbar-logo a {
  outline: none;
}
.cid-sz756eI19K .dropdown-item.active,
.cid-sz756eI19K .dropdown-item:active {
  background-color: transparent;
}
.cid-sz756eI19K .nav-dropdown .link.dropdown-toggle {
  margin-right: 1.667em;
}
.cid-sz756eI19K .nav-dropdown .link.dropdown-toggle[aria-expanded="true"] {
  margin-right: 0;
  padding: 0.667em 1.667em;
}
.cid-sz756eI19K .navbar.navbar-expand-lg .dropdown .dropdown-menu {
  background: #1dc9cb;
}
.cid-sz756eI19K .navbar.navbar-expand-lg .dropdown .dropdown-menu .dropdown-submenu {
  margin: 0;
  left: 100%;
}
.cid-sz756eI19K .navbar .dropdown.open > .dropdown-menu {
  display: block;
}
.cid-sz756eI19K ul.navbar-nav {
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.cid-sz756eI19K .navbar-buttons {
  text-align: center;
}
.cid-sz756eI19K button.navbar-toggler {
  outline: none;
  width: 31px;
  height: 20px;
  cursor: pointer;
  transition: all .2s;
  position: relative;
  -webkit-align-self: center;
  align-self: center;
}
.cid-sz756eI19K button.navbar-toggler .hamburger span {
  position: absolute;
  right: 0;
  width: 30px;
  height: 2px;
  border-right: 5px;
  background-color: #ffffff;
}
.cid-sz756eI19K button.navbar-toggler .hamburger span:nth-child(1) {
  top: 0;
  transition: all .2s;
}
.cid-sz756eI19K button.navbar-toggler .hamburger span:nth-child(2) {
  top: 8px;
  transition: all .15s;
}
.cid-sz756eI19K button.navbar-toggler .hamburger span:nth-child(3) {
  top: 8px;
  transition: all .15s;
}
.cid-sz756eI19K button.navbar-toggler .hamburger span:nth-child(4) {
  top: 16px;
  transition: all .2s;
}
.cid-sz756eI19K nav.opened .hamburger span:nth-child(1) {
  top: 8px;
  width: 0;
  opacity: 0;
  right: 50%;
  transition: all .2s;
}
.cid-sz756eI19K nav.opened .hamburger span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  transition: all .25s;
}
.cid-sz756eI19K nav.opened .hamburger span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  transition: all .25s;
}
.cid-sz756eI19K nav.opened .hamburger span:nth-child(4) {
  top: 8px;
  width: 0;
  opacity: 0;
  right: 50%;
  transition: all .2s;
}
.cid-sz756eI19K .navbar-dropdown {
  padding: .5rem 1rem;
}
.cid-sz756eI19K a.nav-link {
  -webkit-justify-content: center;
  justify-content: center;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
}
.cid-sz756eI19K .mbr-iconfont {
  font-size: 1rem;
  color: #ffffff;
  display: inline-flex;
}
.cid-sz756eI19K .mbr-iconfont:before {
  padding: .5rem;
  border: 2px solid;
  border-radius: 100px;
}
.cid-sz756eI19K .soc-item {
  margin: .5rem .3rem;
}
.cid-sz756eI19K .icons-menu {
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  padding-left: 1rem;
  text-align: center;
  -webkit-align-items: center;
  align-items: center;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .cid-sz756eI19K .navbar {
    height: 77px;
  }
  .cid-sz756eI19K .navbar.opened {
    height: auto;
  }
  .cid-sz756eI19K .nav-item .nav-link:hover::before {
    width: 175%;
    max-width: calc(100% + 2rem);
    left: -1rem;
  }
}
.cid-ss7VBXR7pC {
  padding-top: 150px;
  padding-bottom: 30px;
  background: linear-gradient(to right, #354a5c 0%, #354a5c 30%, #082136 30%, #082136 100%);
  overflow: hidden !important;
}
.cid-ss7VBXR7pC .animated-element {
  color: #efefef;
}
.cid-ss7VBXR7pC .img-block {
  align-self: center;
}
.cid-ss7VBXR7pC .mbr-section-subtitle {
  color: #efefef;
}
@media (min-width: 992px) {
  .cid-ss7VBXR7pC .img-block {
    padding-right: 2rem;
  }
}
@media (max-width: 767px) {
  .cid-ss7VBXR7pC .mbr-figure {
    margin-top: 1rem;
  }
}
@media (max-width: 768px) {
  .cid-ss7VBXR7pC {
    background: #082136;
  }
  .cid-ss7VBXR7pC .img-block {
    padding-bottom: 2rem;
  }
}
.cid-ss7VBXR7pC .mbr-section-title {
  text-align: center;
}
.cid-srgqNP4gAy {
  padding-top: 17px;
  padding-bottom: 0px;
  background-color: #ffffff;
}
.cid-srgqNP4gAy h2 {
  text-align: center;
}
.cid-srgqNP4gAy h3 {
  text-align: center;
  font-weight: 300;
}
.cid-srgqNP4gAy p {
  color: #767676;
}
.cid-srgqNP4gAy .block-content {
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  flex-basis: 100%;
  -webkit-flex-basis: 100%;
}
.cid-srgqNP4gAy .mbr-figure {
  align-self: flex-start;
  -webkit-align-self: flex-start;
  flex-shrink: 0;
  -webkit-flex-shrink: 0;
}
.cid-srgqNP4gAy .media-container-row {
  word-wrap: break-word;
  word-break: break-word;
}
.cid-srgqNP4gAy .mbr-section-subtitle {
  color: #000000;
}
.cid-srgqNP4gAy .card-title {
  font-weight: 500;
}
.cid-srgqNP4gAy .card-img {
  text-align: inherit;
}
.cid-srgqNP4gAy .card-img span {
  font-size: 48px;
  color: #707070;
}
@media (min-width: 992px) {
  .cid-srgqNP4gAy .mbr-figure {
    padding-right: 2rem;
    padding-left: 2rem;
  }
}
@media (max-width: 991px) {
  .cid-srgqNP4gAy .mbr-figure {
    margin-bottom: 2rem;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .cid-srgqNP4gAy .mbr-figure {
    padding-right: 0;
    padding-bottom: 1rem;
    padding-top: 1rem;
  }
  .cid-srgqNP4gAy .block-content {
    flex-direction: row;
    -webkit-flex-direction: row;
    text-align: center;
  }
  .cid-srgqNP4gAy .block-content .card {
    flex-basis: 100%;
    -webkit-flex-basis: 100%;
  }
}
.cid-srgqNP4gAy .mbr-section-subtitle DIV {
  text-align: left;
  color: #000000;
}
.cid-srgqNP4gAy .mbr-crt-title {
  text-align: center;
}
.cid-srgqNP4gAy .mbr-section-text {
  text-align: left;
  color: #082136;
}
.cid-srnlw5KFIp {
  padding-top: 60px;
  padding-bottom: 60px;
  background: #cccccc;
}
.cid-srnlw5KFIp .underline .line {
  width: 2rem;
  height: 1px;
  background: #ffffff;
  display: inline-block;
}
.cid-srnlw5KFIp .main-head {
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  justify-content: center;
}
.cid-srnlw5KFIp .first-table {
  margin: 0;
}
.cid-srnlw5KFIp .first-table.two-items {
  margin-right: 15px;
}
.cid-srnlw5KFIp .first-table .table-head-wrapper {
  background: #08579f;
}
.cid-srnlw5KFIp .second-table {
  margin: 0;
  margin-left: 15px;
}
.cid-srnlw5KFIp .second-table .table-head-wrapper {
  background: #098484;
}
.cid-srnlw5KFIp .table {
  padding: 2rem;
  background: #ffffff;
  position: relative;
}
.cid-srnlw5KFIp .table .table-wrapper .table-head-wrapper {
  margin: -2rem;
  margin-bottom: 0 !important;
  padding: 2rem;
  padding-bottom: 0 !important;
}
.cid-srnlw5KFIp .table .table-wrapper .table-head-wrapper span {
  width: 100%;
  display: block;
}
.cid-srnlw5KFIp .table .table-wrapper .table-purchase {
  padding-bottom: 2rem;
}
.cid-srnlw5KFIp .table .table-wrapper .table-purchase .table-pricing {
  margin: 0;
  margin-top: .5rem !important;
}
.cid-srnlw5KFIp .table .table-wrapper .list-group {
  padding-top: 2rem;
  list-style: none;
}
.cid-srnlw5KFIp .table .table-wrapper .list-group li {
  padding: .4rem;
  border-bottom: 1px solid #f4f8fa;
}
.cid-srnlw5KFIp .table .table-wrapper .list-group li .mbr-iconfont {
  font-size: 30px;
}
@media (max-width: 767px) {
  .cid-srnlw5KFIp .first-table,
  .cid-srnlw5KFIp .second-table {
    margin: 0 15px;
  }
  .cid-srnlw5KFIp .first-table {
    margin-bottom: 1rem;
  }
}
@media (min-width: 768px) {
  .cid-srnlw5KFIp .items-row {
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .cid-srnlw5KFIp .first-table,
  .cid-srnlw5KFIp .second-table {
    max-width: calc(50% - 15px);
  }
}
@media (max-width: 991px) {
  .cid-srnlw5KFIp .main-head {
    padding-bottom: 2rem;
  }
}
.cid-srnlw5KFIp .table-heading {
  text-align: center;
  color: #ffffff;
}
.cid-srnlw5KFIp .pricing-value {
  text-align: center;
  color: #ffffff;
}
.cid-srnlw5KFIp .table-pricing {
  text-align: center;
  color: #ffffff;
}
.cid-srnlw5KFIp .main-title {
  color: #000000;
}
.cid-srobQlefAJ.popup-builder {
  background-color: #ffffff;
}
.cid-srobQlefAJ.popup-builder .modal {
  position: relative;
  display: block;
  z-index: 1;
}
.cid-srobQlefAJ.popup-builder .modal-dialog {
  margin-top: 60px;
  margin-bottom: 60px;
}
.cid-srobQlefAJ .modal-content,
.cid-srobQlefAJ .modal-dialog {
  height: auto;
}
.cid-srobQlefAJ .form-wrapper .input-group-btn {
  margin-right: auto;
  margin-left: auto;
}
.cid-srobQlefAJ .form-wrapper .input-group-btn .btn {
  margin: 0 !important;
}
@media (min-width: 769px) {
  .cid-srobQlefAJ .form-wrapper .mbr-form .form-group,
  .cid-srobQlefAJ .form-wrapper .mbr-form .input-group-btn {
    padding: 0 .5rem;
  }
}
.cid-srobQlefAJ .card-img {
  width: 100%;
  margin: auto;
  border-radius: 0;
}
.cid-srobQlefAJ .mbr-figure img {
  display: block;
  width: 100%;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  -webkit-align-self: center;
  align-self: center;
}
.cid-srobQlefAJ .mbr-text {
  text-align: center;
}
.cid-srobQlefAJ .pt-0 {
  padding-top: 0 !important;
}
.cid-srobQlefAJ .pb-0 {
  padding-bottom: 0 !important;
}
.cid-srobQlefAJ .form-content {
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}
.cid-srobQlefAJ .mbr-overlay {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
  pointer-events: none;
}
.cid-srobQlefAJ .modal-open {
  overflow: hidden;
}
.cid-srobQlefAJ .modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.cid-srobQlefAJ .modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}
.cid-srobQlefAJ .modal-dialog {
  position: relative;
  width: auto;
  margin: .5rem;
  pointer-events: none;
}
.cid-srobQlefAJ .modal.fade .modal-dialog {
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
}
.cid-srobQlefAJ .modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}
.cid-srobQlefAJ .modal-dialog-centered {
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - (.5rem * 2));
}
.cid-srobQlefAJ .modal-dialog-centered::before {
  display: block;
  height: calc(100vh - (.5rem * 2));
  content: "";
}
.cid-srobQlefAJ .modal-content {
  background: #ffffff;
  position: relative;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-clip: padding-box;
  border: none;
  outline: 0;
  -webkit-box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
}
.cid-srobQlefAJ .modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.cid-srobQlefAJ .modal-backdrop.fade {
  opacity: 0;
}
.cid-srobQlefAJ .modal-backdrop.show {
  opacity: .5;
}
.cid-srobQlefAJ .modal-header {
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: none;
}
.cid-srobQlefAJ .modal-header .close {
  position: absolute;
  top: auto;
  right: 1rem;
  margin: -1rem -1rem -1rem auto;
  padding: 1rem;
  opacity: .75;
}
.cid-srobQlefAJ .modal-header .close:hover {
  opacity: 1;
}
.cid-srobQlefAJ .modal-header .close:focus {
  outline: none;
}
.cid-srobQlefAJ .modal-title {
  line-height: 1.5;
  width: 100%;
  margin: 0;
  text-align: center;
}
.cid-srobQlefAJ .modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
  min-height: 100%;
}
.cid-srobQlefAJ .modal-footer {
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 1rem;
  border-top: none;
  text-align: center;
}
.cid-srobQlefAJ .modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (min-width: 576px) {
  .cid-srobQlefAJ .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .cid-srobQlefAJ .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }
  .cid-srobQlefAJ .modal-dialog-centered::before {
    height: calc(100vh - (1.75rem * 2));
  }
  .cid-srobQlefAJ .modal-sm {
    max-width: 300px;
  }
  .cid-srobQlefAJ .container {
    max-width: 540px;
  }
}
@media (min-width: 992px) {
  .cid-srobQlefAJ .modal-lg,
  .cid-srobQlefAJ .modal-xl {
    max-width: 800px;
  }
  .cid-srobQlefAJ .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .cid-srobQlefAJ .modal-xl {
    max-width: 1140px;
  }
  .cid-srobQlefAJ .container {
    max-width: 1140px;
  }
}
.cid-srobQlefAJ .container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .cid-srobQlefAJ .container {
    max-width: 720px;
  }
}
.cid-srobQlefAJ .row {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.cid-srobQlefAJ .col-md-4 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.cid-srobQlefAJ .form-group {
  margin-bottom: 1rem;
}
.cid-srobQlefAJ .form-control {
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}
.cid-srobQlefAJ .col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}
.cid-srobQlefAJ .col-md-auto {
  position: relative;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}
.cid-srQEPH6Mv2.popup-builder {
  background-color: #ffffff;
}
.cid-srQEPH6Mv2.popup-builder .modal {
  position: relative;
  display: block;
  z-index: 1;
}
.cid-srQEPH6Mv2.popup-builder .modal-dialog {
  margin-top: 60px;
  margin-bottom: 60px;
}
.cid-srQEPH6Mv2 .modal-content,
.cid-srQEPH6Mv2 .modal-dialog {
  height: auto;
}
.cid-srQEPH6Mv2 .form-wrapper .input-group-btn {
  margin-right: auto;
  margin-left: auto;
}
.cid-srQEPH6Mv2 .form-wrapper .input-group-btn .btn {
  margin: 0 !important;
}
@media (min-width: 769px) {
  .cid-srQEPH6Mv2 .form-wrapper .mbr-form .form-group,
  .cid-srQEPH6Mv2 .form-wrapper .mbr-form .input-group-btn {
    padding: 0 .5rem;
  }
}
.cid-srQEPH6Mv2 .card-img {
  width: 100%;
  margin: auto;
  border-radius: 0;
}
.cid-srQEPH6Mv2 .mbr-figure img {
  display: block;
  width: 100%;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  -webkit-align-self: center;
  align-self: center;
}
.cid-srQEPH6Mv2 .mbr-text {
  text-align: center;
}
.cid-srQEPH6Mv2 .pt-0 {
  padding-top: 0 !important;
}
.cid-srQEPH6Mv2 .pb-0 {
  padding-bottom: 0 !important;
}
.cid-srQEPH6Mv2 .form-content {
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}
.cid-srQEPH6Mv2 .mbr-overlay {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
  pointer-events: none;
}
.cid-srQEPH6Mv2 .modal-open {
  overflow: hidden;
}
.cid-srQEPH6Mv2 .modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.cid-srQEPH6Mv2 .modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}
.cid-srQEPH6Mv2 .modal-dialog {
  position: relative;
  width: auto;
  margin: .5rem;
  pointer-events: none;
}
.cid-srQEPH6Mv2 .modal.fade .modal-dialog {
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
}
.cid-srQEPH6Mv2 .modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}
.cid-srQEPH6Mv2 .modal-dialog-centered {
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - (.5rem * 2));
}
.cid-srQEPH6Mv2 .modal-dialog-centered::before {
  display: block;
  height: calc(100vh - (.5rem * 2));
  content: "";
}
.cid-srQEPH6Mv2 .modal-content {
  background: #ffffff;
  position: relative;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-clip: padding-box;
  border: none;
  outline: 0;
  -webkit-box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
}
.cid-srQEPH6Mv2 .modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.cid-srQEPH6Mv2 .modal-backdrop.fade {
  opacity: 0;
}
.cid-srQEPH6Mv2 .modal-backdrop.show {
  opacity: .5;
}
.cid-srQEPH6Mv2 .modal-header {
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: none;
}
.cid-srQEPH6Mv2 .modal-header .close {
  position: absolute;
  top: auto;
  right: 1rem;
  margin: -1rem -1rem -1rem auto;
  padding: 1rem;
  opacity: .75;
}
.cid-srQEPH6Mv2 .modal-header .close:hover {
  opacity: 1;
}
.cid-srQEPH6Mv2 .modal-header .close:focus {
  outline: none;
}
.cid-srQEPH6Mv2 .modal-title {
  line-height: 1.5;
  width: 100%;
  margin: 0;
  text-align: center;
}
.cid-srQEPH6Mv2 .modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
  min-height: 100%;
}
.cid-srQEPH6Mv2 .modal-footer {
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 1rem;
  border-top: none;
  text-align: center;
}
.cid-srQEPH6Mv2 .modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (min-width: 576px) {
  .cid-srQEPH6Mv2 .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .cid-srQEPH6Mv2 .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }
  .cid-srQEPH6Mv2 .modal-dialog-centered::before {
    height: calc(100vh - (1.75rem * 2));
  }
  .cid-srQEPH6Mv2 .modal-sm {
    max-width: 300px;
  }
  .cid-srQEPH6Mv2 .container {
    max-width: 540px;
  }
}
@media (min-width: 992px) {
  .cid-srQEPH6Mv2 .modal-lg,
  .cid-srQEPH6Mv2 .modal-xl {
    max-width: 800px;
  }
  .cid-srQEPH6Mv2 .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .cid-srQEPH6Mv2 .modal-xl {
    max-width: 1140px;
  }
  .cid-srQEPH6Mv2 .container {
    max-width: 1140px;
  }
}
.cid-srQEPH6Mv2 .container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .cid-srQEPH6Mv2 .container {
    max-width: 720px;
  }
}
.cid-srQEPH6Mv2 .row {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.cid-srQEPH6Mv2 .col-md-4 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.cid-srQEPH6Mv2 .form-group {
  margin-bottom: 1rem;
}
.cid-srQEPH6Mv2 .form-control {
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}
.cid-srQEPH6Mv2 .col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}
.cid-srQEPH6Mv2 .col-md-auto {
  position: relative;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}
.cid-srnaqUSvPY {
  padding-top: 180px;
  padding-bottom: 105px;
  background-image: url("../../../assets/images/990-2000x1047.jpg");
}
.cid-srnaqUSvPY .card-box {
  background-color: #ffffff;
  padding: 2rem;
}
.cid-srnaqUSvPY h4 {
  font-weight: 500;
  margin-bottom: 0;
  text-align: left;
}
.cid-srnaqUSvPY p {
  color: #767676;
  text-align: left;
}
.cid-srnaqUSvPY .card-wrapper {
  position: relative;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  transition: box-shadow 0.3s;
}
.cid-srnaqUSvPY .card-wrapper:hover {
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s;
}
.cid-srnaqUSvPY .card-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
}
.cid-srnaqUSvPY P {
  color: #000000;
}
.cid-srnaqUSvPY .card-title {
  text-align: center;
  color: #000000;
}
.cid-sriaVgEM8K {
  padding-top: 80px;
  padding-bottom: 0px;
  background-image: url("../../../assets/images/logo-x-voxov-black-582x605.png");
}
.cid-sriaVgEM8K h2 {
  text-align: center;
}
.cid-sriaVgEM8K h3 {
  text-align: center;
  font-weight: 300;
}
.cid-sriaVgEM8K p {
  color: #767676;
}
.cid-sriaVgEM8K .block-content {
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  flex-basis: 100%;
  -webkit-flex-basis: 100%;
}
.cid-sriaVgEM8K .mbr-figure {
  align-self: flex-start;
  -webkit-align-self: flex-start;
  flex-shrink: 0;
  -webkit-flex-shrink: 0;
}
.cid-sriaVgEM8K .media-container-row {
  word-wrap: break-word;
  word-break: break-word;
}
.cid-sriaVgEM8K .mbr-section-subtitle {
  color: #000000;
}
.cid-sriaVgEM8K .card-title {
  font-weight: 500;
}
.cid-sriaVgEM8K .card-img {
  text-align: inherit;
}
.cid-sriaVgEM8K .card-img span {
  font-size: 48px;
  color: #707070;
}
@media (min-width: 992px) {
  .cid-sriaVgEM8K .mbr-figure {
    padding-right: 2rem;
    padding-left: 2rem;
  }
}
@media (max-width: 991px) {
  .cid-sriaVgEM8K .mbr-figure {
    margin-bottom: 2rem;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .cid-sriaVgEM8K .mbr-figure {
    padding-right: 0;
    padding-bottom: 1rem;
    padding-top: 1rem;
  }
  .cid-sriaVgEM8K .block-content {
    flex-direction: row;
    -webkit-flex-direction: row;
    text-align: center;
  }
  .cid-sriaVgEM8K .block-content .card {
    flex-basis: 100%;
    -webkit-flex-basis: 100%;
  }
}
.cid-sriaVgEM8K .mbr-section-subtitle DIV {
  text-align: left;
  color: #000000;
}
.cid-sriaVgEM8K .mbr-crt-title {
  text-align: center;
}
.cid-sriaVgEM8K .mbr-section-text {
  text-align: left;
  color: #082136;
}
.cid-sriaVnNstf {
  padding-top: 60px;
  padding-bottom: 60px;
  background: #cccccc;
}
.cid-sriaVnNstf .underline .line {
  width: 2rem;
  height: 1px;
  background: #ffffff;
  display: inline-block;
}
.cid-sriaVnNstf .main-head {
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  justify-content: center;
}
.cid-sriaVnNstf .first-table {
  margin: 0;
}
.cid-sriaVnNstf .first-table.two-items {
  margin-right: 15px;
}
.cid-sriaVnNstf .first-table .table-head-wrapper {
  background: #08579f;
}
.cid-sriaVnNstf .second-table {
  margin: 0;
  margin-left: 15px;
}
.cid-sriaVnNstf .second-table .table-head-wrapper {
  background: #098484;
}
.cid-sriaVnNstf .table {
  padding: 2rem;
  background: #ffffff;
  position: relative;
}
.cid-sriaVnNstf .table .table-wrapper .table-head-wrapper {
  margin: -2rem;
  margin-bottom: 0 !important;
  padding: 2rem;
  padding-bottom: 0 !important;
}
.cid-sriaVnNstf .table .table-wrapper .table-head-wrapper span {
  width: 100%;
  display: block;
}
.cid-sriaVnNstf .table .table-wrapper .table-purchase {
  padding-bottom: 2rem;
}
.cid-sriaVnNstf .table .table-wrapper .table-purchase .table-pricing {
  margin: 0;
  margin-top: .5rem !important;
}
.cid-sriaVnNstf .table .table-wrapper .list-group {
  padding-top: 2rem;
  list-style: none;
}
.cid-sriaVnNstf .table .table-wrapper .list-group li {
  padding: .4rem;
  border-bottom: 1px solid #f4f8fa;
}
.cid-sriaVnNstf .table .table-wrapper .list-group li .mbr-iconfont {
  font-size: 30px;
}
@media (max-width: 767px) {
  .cid-sriaVnNstf .first-table,
  .cid-sriaVnNstf .second-table {
    margin: 0 15px;
  }
  .cid-sriaVnNstf .first-table {
    margin-bottom: 1rem;
  }
}
@media (min-width: 768px) {
  .cid-sriaVnNstf .items-row {
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .cid-sriaVnNstf .first-table,
  .cid-sriaVnNstf .second-table {
    max-width: calc(50% - 15px);
  }
}
@media (max-width: 991px) {
  .cid-sriaVnNstf .main-head {
    padding-bottom: 2rem;
  }
}
.cid-sriaVnNstf .table-heading {
  text-align: center;
  color: #ffffff;
}
.cid-sriaVnNstf .pricing-value {
  text-align: center;
  color: #ffffff;
}
.cid-sriaVnNstf .table-pricing {
  text-align: center;
  color: #ffffff;
}
.cid-sriaVnNstf .main-title {
  color: #000000;
}
.cid-sro3Umv2nl.popup-builder {
  background-color: #ffffff;
}
.cid-sro3Umv2nl.popup-builder .modal {
  position: relative;
  display: block;
  z-index: 1;
}
.cid-sro3Umv2nl.popup-builder .modal-dialog {
  margin-top: 60px;
  margin-bottom: 60px;
}
.cid-sro3Umv2nl .modal-content,
.cid-sro3Umv2nl .modal-dialog {
  height: auto;
}
.cid-sro3Umv2nl .form-wrapper .input-group-btn {
  margin-right: auto;
  margin-left: auto;
}
.cid-sro3Umv2nl .form-wrapper .input-group-btn .btn {
  margin: 0 !important;
}
@media (min-width: 769px) {
  .cid-sro3Umv2nl .form-wrapper .mbr-form .form-group,
  .cid-sro3Umv2nl .form-wrapper .mbr-form .input-group-btn {
    padding: 0 .5rem;
  }
}
.cid-sro3Umv2nl .card-img {
  width: 100%;
  margin: auto;
  border-radius: 0;
}
.cid-sro3Umv2nl .mbr-figure img {
  display: block;
  width: 100%;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  -webkit-align-self: center;
  align-self: center;
}
.cid-sro3Umv2nl .mbr-text {
  text-align: center;
}
.cid-sro3Umv2nl .pt-0 {
  padding-top: 0 !important;
}
.cid-sro3Umv2nl .pb-0 {
  padding-bottom: 0 !important;
}
.cid-sro3Umv2nl .form-content {
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}
.cid-sro3Umv2nl .mbr-overlay {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
  pointer-events: none;
}
.cid-sro3Umv2nl .modal-open {
  overflow: hidden;
}
.cid-sro3Umv2nl .modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.cid-sro3Umv2nl .modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}
.cid-sro3Umv2nl .modal-dialog {
  position: relative;
  width: auto;
  margin: .5rem;
  pointer-events: none;
}
.cid-sro3Umv2nl .modal.fade .modal-dialog {
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
}
.cid-sro3Umv2nl .modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}
.cid-sro3Umv2nl .modal-dialog-centered {
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - (.5rem * 2));
}
.cid-sro3Umv2nl .modal-dialog-centered::before {
  display: block;
  height: calc(100vh - (.5rem * 2));
  content: "";
}
.cid-sro3Umv2nl .modal-content {
  background: #ffffff;
  position: relative;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-clip: padding-box;
  border: none;
  outline: 0;
  -webkit-box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
}
.cid-sro3Umv2nl .modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.cid-sro3Umv2nl .modal-backdrop.fade {
  opacity: 0;
}
.cid-sro3Umv2nl .modal-backdrop.show {
  opacity: .5;
}
.cid-sro3Umv2nl .modal-header {
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: none;
}
.cid-sro3Umv2nl .modal-header .close {
  position: absolute;
  top: auto;
  right: 1rem;
  margin: -1rem -1rem -1rem auto;
  padding: 1rem;
  opacity: .75;
}
.cid-sro3Umv2nl .modal-header .close:hover {
  opacity: 1;
}
.cid-sro3Umv2nl .modal-header .close:focus {
  outline: none;
}
.cid-sro3Umv2nl .modal-title {
  line-height: 1.5;
  width: 100%;
  margin: 0;
  text-align: center;
}
.cid-sro3Umv2nl .modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
  min-height: 100%;
}
.cid-sro3Umv2nl .modal-footer {
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 1rem;
  border-top: none;
  text-align: center;
}
.cid-sro3Umv2nl .modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (min-width: 576px) {
  .cid-sro3Umv2nl .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .cid-sro3Umv2nl .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }
  .cid-sro3Umv2nl .modal-dialog-centered::before {
    height: calc(100vh - (1.75rem * 2));
  }
  .cid-sro3Umv2nl .modal-sm {
    max-width: 300px;
  }
  .cid-sro3Umv2nl .container {
    max-width: 540px;
  }
}
@media (min-width: 992px) {
  .cid-sro3Umv2nl .modal-lg,
  .cid-sro3Umv2nl .modal-xl {
    max-width: 800px;
  }
  .cid-sro3Umv2nl .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .cid-sro3Umv2nl .modal-xl {
    max-width: 1140px;
  }
  .cid-sro3Umv2nl .container {
    max-width: 1140px;
  }
}
.cid-sro3Umv2nl .container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .cid-sro3Umv2nl .container {
    max-width: 720px;
  }
}
.cid-sro3Umv2nl .row {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.cid-sro3Umv2nl .col-md-4 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.cid-sro3Umv2nl .form-group {
  margin-bottom: 1rem;
}
.cid-sro3Umv2nl .form-control {
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}
.cid-sro3Umv2nl .col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}
.cid-sro3Umv2nl .col-md-auto {
  position: relative;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}
.cid-srQEPH9aB0.popup-builder {
  background-color: #ffffff;
}
.cid-srQEPH9aB0.popup-builder .modal {
  position: relative;
  display: block;
  z-index: 1;
}
.cid-srQEPH9aB0.popup-builder .modal-dialog {
  margin-top: 60px;
  margin-bottom: 60px;
}
.cid-srQEPH9aB0 .modal-content,
.cid-srQEPH9aB0 .modal-dialog {
  height: auto;
}
.cid-srQEPH9aB0 .form-wrapper .input-group-btn {
  margin-right: auto;
  margin-left: auto;
}
.cid-srQEPH9aB0 .form-wrapper .input-group-btn .btn {
  margin: 0 !important;
}
@media (min-width: 769px) {
  .cid-srQEPH9aB0 .form-wrapper .mbr-form .form-group,
  .cid-srQEPH9aB0 .form-wrapper .mbr-form .input-group-btn {
    padding: 0 .5rem;
  }
}
.cid-srQEPH9aB0 .card-img {
  width: 100%;
  margin: auto;
  border-radius: 0;
}
.cid-srQEPH9aB0 .mbr-figure img {
  display: block;
  width: 100%;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  -webkit-align-self: center;
  align-self: center;
}
.cid-srQEPH9aB0 .mbr-text {
  text-align: center;
}
.cid-srQEPH9aB0 .pt-0 {
  padding-top: 0 !important;
}
.cid-srQEPH9aB0 .pb-0 {
  padding-bottom: 0 !important;
}
.cid-srQEPH9aB0 .form-content {
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}
.cid-srQEPH9aB0 .mbr-overlay {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
  pointer-events: none;
}
.cid-srQEPH9aB0 .modal-open {
  overflow: hidden;
}
.cid-srQEPH9aB0 .modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.cid-srQEPH9aB0 .modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}
.cid-srQEPH9aB0 .modal-dialog {
  position: relative;
  width: auto;
  margin: .5rem;
  pointer-events: none;
}
.cid-srQEPH9aB0 .modal.fade .modal-dialog {
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
}
.cid-srQEPH9aB0 .modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}
.cid-srQEPH9aB0 .modal-dialog-centered {
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - (.5rem * 2));
}
.cid-srQEPH9aB0 .modal-dialog-centered::before {
  display: block;
  height: calc(100vh - (.5rem * 2));
  content: "";
}
.cid-srQEPH9aB0 .modal-content {
  background: #ffffff;
  position: relative;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-clip: padding-box;
  border: none;
  outline: 0;
  -webkit-box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
}
.cid-srQEPH9aB0 .modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.cid-srQEPH9aB0 .modal-backdrop.fade {
  opacity: 0;
}
.cid-srQEPH9aB0 .modal-backdrop.show {
  opacity: .5;
}
.cid-srQEPH9aB0 .modal-header {
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: none;
}
.cid-srQEPH9aB0 .modal-header .close {
  position: absolute;
  top: auto;
  right: 1rem;
  margin: -1rem -1rem -1rem auto;
  padding: 1rem;
  opacity: .75;
}
.cid-srQEPH9aB0 .modal-header .close:hover {
  opacity: 1;
}
.cid-srQEPH9aB0 .modal-header .close:focus {
  outline: none;
}
.cid-srQEPH9aB0 .modal-title {
  line-height: 1.5;
  width: 100%;
  margin: 0;
  text-align: center;
}
.cid-srQEPH9aB0 .modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
  min-height: 100%;
}
.cid-srQEPH9aB0 .modal-footer {
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 1rem;
  border-top: none;
  text-align: center;
}
.cid-srQEPH9aB0 .modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (min-width: 576px) {
  .cid-srQEPH9aB0 .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .cid-srQEPH9aB0 .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }
  .cid-srQEPH9aB0 .modal-dialog-centered::before {
    height: calc(100vh - (1.75rem * 2));
  }
  .cid-srQEPH9aB0 .modal-sm {
    max-width: 300px;
  }
  .cid-srQEPH9aB0 .container {
    max-width: 540px;
  }
}
@media (min-width: 992px) {
  .cid-srQEPH9aB0 .modal-lg,
  .cid-srQEPH9aB0 .modal-xl {
    max-width: 800px;
  }
  .cid-srQEPH9aB0 .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .cid-srQEPH9aB0 .modal-xl {
    max-width: 1140px;
  }
  .cid-srQEPH9aB0 .container {
    max-width: 1140px;
  }
}
.cid-srQEPH9aB0 .container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .cid-srQEPH9aB0 .container {
    max-width: 720px;
  }
}
.cid-srQEPH9aB0 .row {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.cid-srQEPH9aB0 .col-md-4 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.cid-srQEPH9aB0 .form-group {
  margin-bottom: 1rem;
}
.cid-srQEPH9aB0 .form-control {
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}
.cid-srQEPH9aB0 .col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}
.cid-srQEPH9aB0 .col-md-auto {
  position: relative;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}
.cid-srWQKUoXVw {
  padding-top: 240px;
  padding-bottom: 105px;
  background-image: url("../../../assets/images/mbr-2-676x514.jpeg");
  overflow: hidden !important;
}
.cid-srWQKUoXVw .animated-element {
  color: #efefef;
}
.cid-srWQKUoXVw .img-block {
  align-self: center;
}
.cid-srWQKUoXVw .mbr-section-subtitle {
  color: #efefef;
}
@media (min-width: 992px) {
  .cid-srWQKUoXVw .img-block {
    padding-right: 2rem;
  }
}
@media (max-width: 767px) {
  .cid-srWQKUoXVw .mbr-figure {
    margin-top: 1rem;
  }
}
@media (max-width: 768px) {
  .cid-srWQKUoXVw .img-block {
    padding-bottom: 2rem;
  }
}
.cid-srWQKUoXVw .mbr-section-title {
  text-align: center;
}
.cid-srWQKUoXVw .mbr-text,
.cid-srWQKUoXVw .mbr-section-btn {
  text-align: center;
}
.cid-sputpcdIXu {
  padding-top: 100px;
  padding-bottom: 60px;
  background: #efefef;
}
.cid-sputpcdIXu .underline .line {
  width: 2rem;
  height: 1px;
  background: #ffffff;
  display: inline-block;
}
.cid-sputpcdIXu .main-head {
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  justify-content: center;
}
.cid-sputpcdIXu .first-table {
  margin: 0;
}
.cid-sputpcdIXu .first-table.two-items {
  margin-right: 15px;
}
.cid-sputpcdIXu .first-table .table-head-wrapper {
  background: #1b2935;
}
.cid-sputpcdIXu .second-table {
  margin: 0;
  margin-left: 15px;
}
.cid-sputpcdIXu .second-table .table-head-wrapper {
  background: #098484;
}
.cid-sputpcdIXu .table {
  padding: 2rem;
  background: #ffffff;
  position: relative;
}
.cid-sputpcdIXu .table .table-wrapper .table-head-wrapper {
  margin: -2rem;
  margin-bottom: 0 !important;
  padding: 2rem;
  padding-bottom: 0 !important;
}
.cid-sputpcdIXu .table .table-wrapper .table-head-wrapper span {
  width: 100%;
  display: block;
}
.cid-sputpcdIXu .table .table-wrapper .table-purchase {
  padding-bottom: 2rem;
}
.cid-sputpcdIXu .table .table-wrapper .table-purchase .table-pricing {
  margin: 0;
  margin-top: .5rem !important;
}
.cid-sputpcdIXu .table .table-wrapper .list-group {
  padding-top: 2rem;
  list-style: none;
}
.cid-sputpcdIXu .table .table-wrapper .list-group li {
  padding: .4rem;
  border-bottom: 1px solid #f4f8fa;
}
.cid-sputpcdIXu .table .table-wrapper .list-group li .mbr-iconfont {
  font-size: 30px;
}
@media (max-width: 767px) {
  .cid-sputpcdIXu .first-table,
  .cid-sputpcdIXu .second-table {
    margin: 0 15px;
  }
  .cid-sputpcdIXu .first-table {
    margin-bottom: 1rem;
  }
}
@media (min-width: 768px) {
  .cid-sputpcdIXu .items-row {
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .cid-sputpcdIXu .first-table,
  .cid-sputpcdIXu .second-table {
    max-width: calc(50% - 15px);
  }
}
@media (max-width: 991px) {
  .cid-sputpcdIXu .main-head {
    padding-bottom: 2rem;
  }
}
.cid-sputpcdIXu .table-heading {
  text-align: center;
  color: #ffffff;
}
.cid-sputpcdIXu .pricing-value {
  text-align: center;
  color: #ffffff;
}
.cid-sputpcdIXu .table-pricing {
  text-align: center;
  color: #ffffff;
}
.cid-sputpcdIXu .main-title {
  color: #082136;
}
.cid-sputpdtcBJ {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #efefef;
}
.cid-sputpdtcBJ .mbr-text,
.cid-sputpdtcBJ .mbr-section-btn {
  color: #082136;
}
.cid-sputpdtcBJ p {
  z-index: 2;
  position: relative;
}
.cid-sputpdtcBJ .author-box {
  display: flex;
  flex-direction: row;
}
.cid-sputpdtcBJ .col-lg-12 {
  box-shadow: 0 10px 55px 5px rgba(87, 227, 177, 0.35);
  padding: 1rem 1.5rem;
}
.cid-sputpdtcBJ .ico1 {
  font-size: 2rem;
}
.cid-sputpdtcBJ .ico2 {
  position: absolute;
  bottom: 15%;
  right: 1.5rem;
  font-size: 8rem;
  z-index: 1;
  opacity: 0.1;
}
.cid-sputpdtcBJ H3 {
  color: #149dcc;
}
.cid-srQvZqHZ7m.popup-builder {
  background-color: #ffffff;
}
.cid-srQvZqHZ7m.popup-builder .modal {
  position: relative;
  display: block;
  z-index: 1;
}
.cid-srQvZqHZ7m.popup-builder .modal-dialog {
  margin-top: 60px;
  margin-bottom: 60px;
}
.cid-srQvZqHZ7m .modal-content,
.cid-srQvZqHZ7m .modal-dialog {
  height: auto;
}
.cid-srQvZqHZ7m .form-wrapper .input-group-btn {
  margin-right: auto;
  margin-left: auto;
}
.cid-srQvZqHZ7m .form-wrapper .input-group-btn .btn {
  margin: 0 !important;
}
@media (min-width: 769px) {
  .cid-srQvZqHZ7m .form-wrapper .mbr-form .form-group,
  .cid-srQvZqHZ7m .form-wrapper .mbr-form .input-group-btn {
    padding: 0 .5rem;
  }
}
.cid-srQvZqHZ7m .card-img {
  width: 100%;
  margin: auto;
  border-radius: 0;
}
.cid-srQvZqHZ7m .mbr-figure img {
  display: block;
  width: 100%;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  -webkit-align-self: center;
  align-self: center;
}
.cid-srQvZqHZ7m .mbr-text {
  text-align: center;
}
.cid-srQvZqHZ7m .pt-0 {
  padding-top: 0 !important;
}
.cid-srQvZqHZ7m .pb-0 {
  padding-bottom: 0 !important;
}
.cid-srQvZqHZ7m .form-content {
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}
.cid-srQvZqHZ7m .mbr-overlay {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
  pointer-events: none;
}
.cid-srQvZqHZ7m .modal-open {
  overflow: hidden;
}
.cid-srQvZqHZ7m .modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.cid-srQvZqHZ7m .modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}
.cid-srQvZqHZ7m .modal-dialog {
  position: relative;
  width: auto;
  margin: .5rem;
  pointer-events: none;
}
.cid-srQvZqHZ7m .modal.fade .modal-dialog {
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
}
.cid-srQvZqHZ7m .modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}
.cid-srQvZqHZ7m .modal-dialog-centered {
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - (.5rem * 2));
}
.cid-srQvZqHZ7m .modal-dialog-centered::before {
  display: block;
  height: calc(100vh - (.5rem * 2));
  content: "";
}
.cid-srQvZqHZ7m .modal-content {
  background: #ffffff;
  position: relative;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-clip: padding-box;
  border: none;
  outline: 0;
  -webkit-box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
}
.cid-srQvZqHZ7m .modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.cid-srQvZqHZ7m .modal-backdrop.fade {
  opacity: 0;
}
.cid-srQvZqHZ7m .modal-backdrop.show {
  opacity: .5;
}
.cid-srQvZqHZ7m .modal-header {
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: none;
}
.cid-srQvZqHZ7m .modal-header .close {
  position: absolute;
  top: auto;
  right: 1rem;
  margin: -1rem -1rem -1rem auto;
  padding: 1rem;
  opacity: .75;
}
.cid-srQvZqHZ7m .modal-header .close:hover {
  opacity: 1;
}
.cid-srQvZqHZ7m .modal-header .close:focus {
  outline: none;
}
.cid-srQvZqHZ7m .modal-title {
  line-height: 1.5;
  width: 100%;
  margin: 0;
  text-align: center;
}
.cid-srQvZqHZ7m .modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
  min-height: 100%;
}
.cid-srQvZqHZ7m .modal-footer {
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 1rem;
  border-top: none;
  text-align: center;
}
.cid-srQvZqHZ7m .modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (min-width: 576px) {
  .cid-srQvZqHZ7m .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .cid-srQvZqHZ7m .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }
  .cid-srQvZqHZ7m .modal-dialog-centered::before {
    height: calc(100vh - (1.75rem * 2));
  }
  .cid-srQvZqHZ7m .modal-sm {
    max-width: 300px;
  }
  .cid-srQvZqHZ7m .container {
    max-width: 540px;
  }
}
@media (min-width: 992px) {
  .cid-srQvZqHZ7m .modal-lg,
  .cid-srQvZqHZ7m .modal-xl {
    max-width: 800px;
  }
  .cid-srQvZqHZ7m .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .cid-srQvZqHZ7m .modal-xl {
    max-width: 1140px;
  }
  .cid-srQvZqHZ7m .container {
    max-width: 1140px;
  }
}
.cid-srQvZqHZ7m .container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .cid-srQvZqHZ7m .container {
    max-width: 720px;
  }
}
.cid-srQvZqHZ7m .row {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.cid-srQvZqHZ7m .col-md-4 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.cid-srQvZqHZ7m .form-group {
  margin-bottom: 1rem;
}
.cid-srQvZqHZ7m .form-control {
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}
.cid-srQvZqHZ7m .col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}
.cid-srQvZqHZ7m .col-md-auto {
  position: relative;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}
.cid-srQEPHcwiv.popup-builder {
  background-color: #ffffff;
}
.cid-srQEPHcwiv.popup-builder .modal {
  position: relative;
  display: block;
  z-index: 1;
}
.cid-srQEPHcwiv.popup-builder .modal-dialog {
  margin-top: 60px;
  margin-bottom: 60px;
}
.cid-srQEPHcwiv .modal-content,
.cid-srQEPHcwiv .modal-dialog {
  height: auto;
}
.cid-srQEPHcwiv .form-wrapper .input-group-btn {
  margin-right: auto;
  margin-left: auto;
}
.cid-srQEPHcwiv .form-wrapper .input-group-btn .btn {
  margin: 0 !important;
}
@media (min-width: 769px) {
  .cid-srQEPHcwiv .form-wrapper .mbr-form .form-group,
  .cid-srQEPHcwiv .form-wrapper .mbr-form .input-group-btn {
    padding: 0 .5rem;
  }
}
.cid-srQEPHcwiv .card-img {
  width: 100%;
  margin: auto;
  border-radius: 0;
}
.cid-srQEPHcwiv .mbr-figure img {
  display: block;
  width: 100%;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  -webkit-align-self: center;
  align-self: center;
}
.cid-srQEPHcwiv .mbr-text {
  text-align: center;
}
.cid-srQEPHcwiv .pt-0 {
  padding-top: 0 !important;
}
.cid-srQEPHcwiv .pb-0 {
  padding-bottom: 0 !important;
}
.cid-srQEPHcwiv .form-content {
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}
.cid-srQEPHcwiv .mbr-overlay {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
  pointer-events: none;
}
.cid-srQEPHcwiv .modal-open {
  overflow: hidden;
}
.cid-srQEPHcwiv .modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.cid-srQEPHcwiv .modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}
.cid-srQEPHcwiv .modal-dialog {
  position: relative;
  width: auto;
  margin: .5rem;
  pointer-events: none;
}
.cid-srQEPHcwiv .modal.fade .modal-dialog {
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
}
.cid-srQEPHcwiv .modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}
.cid-srQEPHcwiv .modal-dialog-centered {
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - (.5rem * 2));
}
.cid-srQEPHcwiv .modal-dialog-centered::before {
  display: block;
  height: calc(100vh - (.5rem * 2));
  content: "";
}
.cid-srQEPHcwiv .modal-content {
  background: #ffffff;
  position: relative;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-clip: padding-box;
  border: none;
  outline: 0;
  -webkit-box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
}
.cid-srQEPHcwiv .modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.cid-srQEPHcwiv .modal-backdrop.fade {
  opacity: 0;
}
.cid-srQEPHcwiv .modal-backdrop.show {
  opacity: .5;
}
.cid-srQEPHcwiv .modal-header {
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: none;
}
.cid-srQEPHcwiv .modal-header .close {
  position: absolute;
  top: auto;
  right: 1rem;
  margin: -1rem -1rem -1rem auto;
  padding: 1rem;
  opacity: .75;
}
.cid-srQEPHcwiv .modal-header .close:hover {
  opacity: 1;
}
.cid-srQEPHcwiv .modal-header .close:focus {
  outline: none;
}
.cid-srQEPHcwiv .modal-title {
  line-height: 1.5;
  width: 100%;
  margin: 0;
  text-align: center;
}
.cid-srQEPHcwiv .modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
  min-height: 100%;
}
.cid-srQEPHcwiv .modal-footer {
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 1rem;
  border-top: none;
  text-align: center;
}
.cid-srQEPHcwiv .modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (min-width: 576px) {
  .cid-srQEPHcwiv .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .cid-srQEPHcwiv .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }
  .cid-srQEPHcwiv .modal-dialog-centered::before {
    height: calc(100vh - (1.75rem * 2));
  }
  .cid-srQEPHcwiv .modal-sm {
    max-width: 300px;
  }
  .cid-srQEPHcwiv .container {
    max-width: 540px;
  }
}
@media (min-width: 992px) {
  .cid-srQEPHcwiv .modal-lg,
  .cid-srQEPHcwiv .modal-xl {
    max-width: 800px;
  }
  .cid-srQEPHcwiv .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .cid-srQEPHcwiv .modal-xl {
    max-width: 1140px;
  }
  .cid-srQEPHcwiv .container {
    max-width: 1140px;
  }
}
.cid-srQEPHcwiv .container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .cid-srQEPHcwiv .container {
    max-width: 720px;
  }
}
.cid-srQEPHcwiv .row {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.cid-srQEPHcwiv .col-md-4 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.cid-srQEPHcwiv .form-group {
  margin-bottom: 1rem;
}
.cid-srQEPHcwiv .form-control {
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}
.cid-srQEPHcwiv .col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}
.cid-srQEPHcwiv .col-md-auto {
  position: relative;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}
.cid-srnFyNXyco {
  padding-top: 180px;
  padding-bottom: 30px;
  background: linear-gradient(to right, #354a5c 0%, #354a5c 30%, #082136 30%, #082136 100%);
  overflow: hidden !important;
}
.cid-srnFyNXyco .animated-element {
  color: #efefef;
}
.cid-srnFyNXyco .img-block {
  align-self: center;
}
.cid-srnFyNXyco .mbr-section-subtitle {
  color: #efefef;
}
@media (min-width: 992px) {
  .cid-srnFyNXyco .img-block {
    padding-right: 2rem;
  }
}
@media (max-width: 767px) {
  .cid-srnFyNXyco .mbr-figure {
    margin-top: 1rem;
  }
}
@media (max-width: 768px) {
  .cid-srnFyNXyco {
    background: #082136;
  }
  .cid-srnFyNXyco .img-block {
    padding-bottom: 2rem;
  }
}
.cid-srnta27jRX {
  padding-top: 75px;
  padding-bottom: 90px;
  background-color: #efefef;
}
.cid-srnta27jRX .card-box {
  background-color: #ffffff;
  padding: 2rem;
}
.cid-srnta27jRX h4 {
  font-weight: 500;
  margin-bottom: 0;
  text-align: left;
}
.cid-srnta27jRX p {
  color: #767676;
  text-align: left;
}
.cid-srnta27jRX .card-wrapper {
  position: relative;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  transition: box-shadow 0.3s;
}
.cid-srnta27jRX .card-wrapper:hover {
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s;
}
.cid-srnta27jRX .card-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
}
.cid-srnta27jRX P {
  color: #000000;
}
.cid-srnta27jRX .card-title {
  text-align: center;
  color: #000000;
}
.cid-srntf9WE8t {
  padding-top: 0px;
  padding-bottom: 90px;
  background-color: #efefef;
}
.cid-srntf9WE8t .card-box {
  background-color: #ffffff;
  padding: 2rem;
}
.cid-srntf9WE8t h4 {
  font-weight: 500;
  margin-bottom: 0;
  text-align: left;
}
.cid-srntf9WE8t p {
  color: #767676;
  text-align: left;
}
.cid-srntf9WE8t .card-wrapper {
  position: relative;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  transition: box-shadow 0.3s;
}
.cid-srntf9WE8t .card-wrapper:hover {
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s;
}
.cid-srntf9WE8t .card-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
}
.cid-srntf9WE8t P {
  color: #000000;
  text-align: left;
}
.cid-srntf9WE8t .card-title {
  color: #031f28;
  text-align: center;
}
.cid-srntjrFA8u {
  padding-top: 45px;
  padding-bottom: 60px;
  background-color: #f9f9f9;
}
.cid-srntjrFA8u h2 {
  text-align: left;
}
.cid-srntjrFA8u h4 {
  text-align: left;
  font-weight: 500;
}
.cid-srntjrFA8u p {
  color: #767676;
  text-align: left;
}
.cid-srntjrFA8u .aside-content {
  flex-basis: 100%;
}
.cid-srntjrFA8u .block-content {
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  word-break: break-word;
}
.cid-srntjrFA8u .media {
  margin: initial;
  align-items: center;
}
.cid-srntjrFA8u .mbr-figure {
  align-self: flex-start;
  -webkit-align-self: flex-start;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
}
.cid-srntjrFA8u .card-img {
  padding-right: 2rem;
  width: auto;
}
.cid-srntjrFA8u .card-img span {
  font-size: 72px;
  color: #707070;
}
@media (min-width: 992px) {
  .cid-srntjrFA8u .mbr-figure {
    padding-right: 4rem;
    padding-right: 0;
    padding-left: 4rem;
  }
  .cid-srntjrFA8u .media-container-row {
    -webkit-flex-direction: row-reverse;
  }
}
@media (max-width: 991px) {
  .cid-srntjrFA8u .mbr-figure {
    padding-right: 0;
    padding-bottom: 1rem;
    margin-bottom: 2rem;
    padding-bottom: 0;
    margin-bottom: 0;
    padding-top: 1rem;
    margin-top: 2rem;
  }
  .cid-srntjrFA8u .media-container-row {
    -webkit-flex-direction: column-reverse;
  }
}
@media (max-width: 300px) {
  .cid-srntjrFA8u .card-img span {
    font-size: 40px !important;
  }
}
.cid-srntjrFA8u H2 {
  text-align: left;
}
.cid-srntjrFA8u P {
  text-align: left;
}
.cid-srntjrFA8u .mbr-text {
  text-align: left;
  color: #333333;
}
.cid-srQ3WEu0Ju.popup-builder {
  background-color: #ffffff;
}
.cid-srQ3WEu0Ju.popup-builder .modal {
  position: relative;
  display: block;
  z-index: 1;
}
.cid-srQ3WEu0Ju.popup-builder .modal-dialog {
  margin-top: 60px;
  margin-bottom: 60px;
}
.cid-srQ3WEu0Ju .modal-content,
.cid-srQ3WEu0Ju .modal-dialog {
  height: auto;
}
.cid-srQ3WEu0Ju .form-wrapper .input-group-btn {
  margin-right: auto;
  margin-left: auto;
}
.cid-srQ3WEu0Ju .form-wrapper .input-group-btn .btn {
  margin: 0 !important;
}
@media (min-width: 769px) {
  .cid-srQ3WEu0Ju .form-wrapper .mbr-form .form-group,
  .cid-srQ3WEu0Ju .form-wrapper .mbr-form .input-group-btn {
    padding: 0 .5rem;
  }
}
.cid-srQ3WEu0Ju .card-img {
  width: 100%;
  margin: auto;
  border-radius: 0;
}
.cid-srQ3WEu0Ju .mbr-figure img {
  display: block;
  width: 100%;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  -webkit-align-self: center;
  align-self: center;
}
.cid-srQ3WEu0Ju .mbr-text {
  text-align: center;
}
.cid-srQ3WEu0Ju .pt-0 {
  padding-top: 0 !important;
}
.cid-srQ3WEu0Ju .pb-0 {
  padding-bottom: 0 !important;
}
.cid-srQ3WEu0Ju .form-content {
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}
.cid-srQ3WEu0Ju .mbr-overlay {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
  pointer-events: none;
}
.cid-srQ3WEu0Ju .modal-open {
  overflow: hidden;
}
.cid-srQ3WEu0Ju .modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.cid-srQ3WEu0Ju .modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}
.cid-srQ3WEu0Ju .modal-dialog {
  position: relative;
  width: auto;
  margin: .5rem;
  pointer-events: none;
}
.cid-srQ3WEu0Ju .modal.fade .modal-dialog {
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
}
.cid-srQ3WEu0Ju .modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}
.cid-srQ3WEu0Ju .modal-dialog-centered {
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - (.5rem * 2));
}
.cid-srQ3WEu0Ju .modal-dialog-centered::before {
  display: block;
  height: calc(100vh - (.5rem * 2));
  content: "";
}
.cid-srQ3WEu0Ju .modal-content {
  background: #ffffff;
  position: relative;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-clip: padding-box;
  border: none;
  outline: 0;
  -webkit-box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
}
.cid-srQ3WEu0Ju .modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.cid-srQ3WEu0Ju .modal-backdrop.fade {
  opacity: 0;
}
.cid-srQ3WEu0Ju .modal-backdrop.show {
  opacity: .5;
}
.cid-srQ3WEu0Ju .modal-header {
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: none;
}
.cid-srQ3WEu0Ju .modal-header .close {
  position: absolute;
  top: auto;
  right: 1rem;
  margin: -1rem -1rem -1rem auto;
  padding: 1rem;
  opacity: .75;
}
.cid-srQ3WEu0Ju .modal-header .close:hover {
  opacity: 1;
}
.cid-srQ3WEu0Ju .modal-header .close:focus {
  outline: none;
}
.cid-srQ3WEu0Ju .modal-title {
  line-height: 1.5;
  width: 100%;
  margin: 0;
  text-align: center;
}
.cid-srQ3WEu0Ju .modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
  min-height: 100%;
}
.cid-srQ3WEu0Ju .modal-footer {
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 1rem;
  border-top: none;
  text-align: center;
}
.cid-srQ3WEu0Ju .modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (min-width: 576px) {
  .cid-srQ3WEu0Ju .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .cid-srQ3WEu0Ju .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }
  .cid-srQ3WEu0Ju .modal-dialog-centered::before {
    height: calc(100vh - (1.75rem * 2));
  }
  .cid-srQ3WEu0Ju .modal-sm {
    max-width: 300px;
  }
  .cid-srQ3WEu0Ju .container {
    max-width: 540px;
  }
}
@media (min-width: 992px) {
  .cid-srQ3WEu0Ju .modal-lg,
  .cid-srQ3WEu0Ju .modal-xl {
    max-width: 800px;
  }
  .cid-srQ3WEu0Ju .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .cid-srQ3WEu0Ju .modal-xl {
    max-width: 1140px;
  }
  .cid-srQ3WEu0Ju .container {
    max-width: 1140px;
  }
}
.cid-srQ3WEu0Ju .container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .cid-srQ3WEu0Ju .container {
    max-width: 720px;
  }
}
.cid-srQ3WEu0Ju .row {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.cid-srQ3WEu0Ju .col-md-4 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.cid-srQ3WEu0Ju .form-group {
  margin-bottom: 1rem;
}
.cid-srQ3WEu0Ju .form-control {
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}
.cid-srQ3WEu0Ju .col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}
.cid-srQ3WEu0Ju .col-md-auto {
  position: relative;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}
.cid-srnME9lbFm.popup-builder {
  background-color: #ffffff;
}
.cid-srnME9lbFm.popup-builder .modal {
  position: relative;
  display: block;
  z-index: 1;
}
.cid-srnME9lbFm.popup-builder .modal-dialog {
  margin-top: 60px;
  margin-bottom: 60px;
}
.cid-srnME9lbFm .modal-content,
.cid-srnME9lbFm .modal-dialog {
  height: auto;
}
.cid-srnME9lbFm .form-wrapper .input-group-btn {
  margin-right: auto;
  margin-left: auto;
}
.cid-srnME9lbFm .form-wrapper .input-group-btn .btn {
  margin: 0 !important;
}
@media (min-width: 769px) {
  .cid-srnME9lbFm .form-wrapper .mbr-form .form-group,
  .cid-srnME9lbFm .form-wrapper .mbr-form .input-group-btn {
    padding: 0 .5rem;
  }
}
.cid-srnME9lbFm .card-img {
  width: 100%;
  margin: auto;
  border-radius: 0;
}
.cid-srnME9lbFm .mbr-figure img {
  display: block;
  width: 100%;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  -webkit-align-self: center;
  align-self: center;
}
.cid-srnME9lbFm .mbr-text {
  text-align: center;
}
.cid-srnME9lbFm .pt-0 {
  padding-top: 0 !important;
}
.cid-srnME9lbFm .pb-0 {
  padding-bottom: 0 !important;
}
.cid-srnME9lbFm .form-content {
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}
.cid-srnME9lbFm .mbr-overlay {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
  pointer-events: none;
}
.cid-srnME9lbFm .modal-open {
  overflow: hidden;
}
.cid-srnME9lbFm .modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.cid-srnME9lbFm .modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}
.cid-srnME9lbFm .modal-dialog {
  position: relative;
  width: auto;
  margin: .5rem;
  pointer-events: none;
}
.cid-srnME9lbFm .modal.fade .modal-dialog {
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
}
.cid-srnME9lbFm .modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}
.cid-srnME9lbFm .modal-dialog-centered {
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - (.5rem * 2));
}
.cid-srnME9lbFm .modal-dialog-centered::before {
  display: block;
  height: calc(100vh - (.5rem * 2));
  content: "";
}
.cid-srnME9lbFm .modal-content {
  background: #ffffff;
  position: relative;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-clip: padding-box;
  border: none;
  outline: 0;
  -webkit-box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
}
.cid-srnME9lbFm .modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.cid-srnME9lbFm .modal-backdrop.fade {
  opacity: 0;
}
.cid-srnME9lbFm .modal-backdrop.show {
  opacity: .5;
}
.cid-srnME9lbFm .modal-header {
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: none;
}
.cid-srnME9lbFm .modal-header .close {
  position: absolute;
  top: auto;
  right: 1rem;
  margin: -1rem -1rem -1rem auto;
  padding: 1rem;
  opacity: .75;
}
.cid-srnME9lbFm .modal-header .close:hover {
  opacity: 1;
}
.cid-srnME9lbFm .modal-header .close:focus {
  outline: none;
}
.cid-srnME9lbFm .modal-title {
  line-height: 1.5;
  width: 100%;
  margin: 0;
  text-align: center;
}
.cid-srnME9lbFm .modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
  min-height: 100%;
}
.cid-srnME9lbFm .modal-footer {
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 1rem;
  border-top: none;
  text-align: center;
}
.cid-srnME9lbFm .modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (min-width: 576px) {
  .cid-srnME9lbFm .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .cid-srnME9lbFm .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }
  .cid-srnME9lbFm .modal-dialog-centered::before {
    height: calc(100vh - (1.75rem * 2));
  }
  .cid-srnME9lbFm .modal-sm {
    max-width: 300px;
  }
  .cid-srnME9lbFm .container {
    max-width: 540px;
  }
}
@media (min-width: 992px) {
  .cid-srnME9lbFm .modal-lg,
  .cid-srnME9lbFm .modal-xl {
    max-width: 800px;
  }
  .cid-srnME9lbFm .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .cid-srnME9lbFm .modal-xl {
    max-width: 1140px;
  }
  .cid-srnME9lbFm .container {
    max-width: 1140px;
  }
}
.cid-srnME9lbFm .container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .cid-srnME9lbFm .container {
    max-width: 720px;
  }
}
.cid-srnME9lbFm .row {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.cid-srnME9lbFm .col-md-4 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.cid-srnME9lbFm .form-group {
  margin-bottom: 1rem;
}
.cid-srnME9lbFm .form-control {
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}
.cid-srnME9lbFm .col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}
.cid-srnME9lbFm .col-md-auto {
  position: relative;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}
.cid-srtjIZmpyl {
  padding-top: 180px;
  padding-bottom: 45px;
  background: linear-gradient(to right, #354a5c 0%, #354a5c 30%, #082136 30%, #082136 100%);
  overflow: hidden !important;
}
.cid-srtjIZmpyl .animated-element {
  color: #efefef;
}
.cid-srtjIZmpyl .img-block {
  align-self: center;
}
.cid-srtjIZmpyl .mbr-section-subtitle {
  color: #efefef;
}
@media (min-width: 992px) {
  .cid-srtjIZmpyl .img-block {
    padding-right: 2rem;
  }
}
@media (max-width: 767px) {
  .cid-srtjIZmpyl .mbr-figure {
    margin-top: 1rem;
  }
}
@media (max-width: 768px) {
  .cid-srtjIZmpyl {
    background: #082136;
  }
  .cid-srtjIZmpyl .img-block {
    padding-bottom: 2rem;
  }
}
.cid-srtjIZmpyl .mbr-section-title {
  text-align: center;
}
.cid-srtjIZmpyl .mbr-text,
.cid-srtjIZmpyl .mbr-section-btn {
  text-align: center;
}
.cid-srWDqnWjkG {
  padding-top: 60px;
  padding-bottom: 90px;
  background-color: #f4f8fa;
}
.cid-srWDqnWjkG .mbr-iconfont {
  font-size: 34px;
  transition: color .3s;
  padding: 1.5rem;
  position: absolute;
  top: 0;
  color: #fff;
  margin-top: -2.5rem;
  border-radius: 50%;
}
.cid-srWDqnWjkG .mbr-section-title {
  margin: 0;
}
.cid-srWDqnWjkG .mbr-section-subtitle {
  color: #8d97ad;
}
.cid-srWDqnWjkG .card-img {
  text-align: inherit;
}
.cid-srWDqnWjkG .card-img span {
  background: linear-gradient(90deg, #15324d, #1dc9cb);
}
.cid-srWDqnWjkG .mbr-text {
  color: #8d97ad;
}
.cid-srWDqnWjkG .wrapper {
  border-radius: .25rem;
  padding: 2rem;
  background: #ffffff;
  -webkit-box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
  box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
}
@media (max-width: 991px) {
  .cid-srWDqnWjkG .wrapper {
    margin-bottom: 5rem;
  }
}
.cid-srWDqnWjkG .mbr-card-title {
  text-align: center;
}
.cid-srty2LYR4F {
  padding-top: 0px;
  padding-bottom: 90px;
  background-color: #f4f8fa;
}
.cid-srty2LYR4F .mbr-section-subtitle {
  color: #000000;
  text-align: left;
  font-weight: 300;
}
.cid-srty2LYR4F .timeline-text-content {
  padding: 2rem 2.5rem;
  background: #082136;
  margin-right: 2rem;
  border-radius: 40px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.cid-srty2LYR4F .timeline-text-content p {
  margin-bottom: 0;
}
@media (max-width: 768px) {
  .cid-srty2LYR4F .timeline-text-content {
    padding: 1.5rem 0.5rem;
  }
}
.cid-srty2LYR4F .mbr-timeline-title,
.cid-srty2LYR4F .mbr-timeline-text {
  color: #ffffff;
}
.cid-srty2LYR4F .timeline-element {
  margin-bottom: 50px;
  position: relative;
  word-wrap: break-word;
  word-break: break-word;
  display: -webkit-flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
.cid-srty2LYR4F .timeline-element:hover .timeline-text-content {
  -webkit-box-shadow: 0 7px 20px 0px rgba(0, 0, 0, 0.08) !important;
  box-shadow: 0 7px 20px 0px rgba(0, 0, 0, 0.08) !important;
  -webkit-transition: all 0.4s !important;
  transition: all 0.4s !important;
}
.cid-srty2LYR4F .timeline-element:hover .timeline-text-content h4,
.cid-srty2LYR4F .timeline-element:hover .timeline-text-content p {
  -webkit-transition: color 1s !important;
  transition: color 1s !important;
  color: #fc643f !important;
}
.cid-srty2LYR4F .timeline-element:hover .iconsBackground span {
  -webkit-transition: color 1s !important;
  transition: color 1s !important;
  color: #fc643f !important;
}
.cid-srty2LYR4F .timeline-element:hover:before {
  -webkit-transition: background-color 1s !important;
  transition: background-color 1s !important;
  background-color: #fc643f !important;
}
.cid-srty2LYR4F .reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
.cid-srty2LYR4F .reverse .timeline-text-content {
  margin-left: 2rem;
  margin-right: 0;
}
.cid-srty2LYR4F .iconsBackground {
  position: absolute;
  left: 50%;
  width: 60px;
  height: 60px;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
  background-color: #082136;
  top: 20px;
  margin-left: -30px;
}
.cid-srty2LYR4F .iconsBackground span {
  color: #ffffff;
}
.cid-srty2LYR4F .mbr-iconfont {
  position: absolute;
  text-align: center;
  font-size: 35px;
  display: inline-block;
  z-index: 3;
  top: 13px;
  left: 13px;
}
.cid-srty2LYR4F .separline:before {
  top: 20px;
  bottom: 0;
  position: absolute;
  content: "";
  width: 2px;
  background-color: #082136;
  left: calc(50% - 1px);
  height: calc(100% + 4rem);
}
.cid-srty2LYR4F .reverseTimeline {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}
@media (max-width: 768px) {
  .cid-srty2LYR4F .iconsBackground {
    left: 1.5rem;
  }
  .cid-srty2LYR4F .separline:before {
    left: calc(1.5rem - 1px);
  }
  .cid-srty2LYR4F .timeline-text-content {
    margin-left: 3rem !important;
    margin-right: 0 !important;
  }
  .cid-srty2LYR4F .reverse .timeline-text-content {
    margin-right: 0 !important;
  }
}
.cid-srty2LYR4F .mbr-section-subtitle DIV {
  text-align: left;
}
.cid-srtCzjAFXg {
  padding-top: 60px;
  padding-bottom: 60px;
  background: #4d8fc9;
}
.cid-srtCzjAFXg .underline .line {
  width: 2rem;
  height: 1px;
  background: #ffffff;
  display: inline-block;
}
.cid-srtCzjAFXg .main-head {
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  justify-content: center;
}
.cid-srtCzjAFXg .first-table {
  margin: 0;
}
.cid-srtCzjAFXg .first-table.two-items {
  margin-right: 15px;
}
.cid-srtCzjAFXg .first-table .table-head-wrapper {
  background: #15324d;
}
.cid-srtCzjAFXg .second-table {
  margin: 0;
  margin-left: 15px;
}
.cid-srtCzjAFXg .second-table .table-head-wrapper {
  background: #098484;
}
.cid-srtCzjAFXg .table {
  padding: 2rem;
  background: #ffffff;
  position: relative;
}
.cid-srtCzjAFXg .table .table-wrapper .table-head-wrapper {
  margin: -2rem;
  margin-bottom: 0 !important;
  padding: 2rem;
  padding-bottom: 0 !important;
}
.cid-srtCzjAFXg .table .table-wrapper .table-head-wrapper span {
  width: 100%;
  display: block;
}
.cid-srtCzjAFXg .table .table-wrapper .table-purchase {
  padding-bottom: 2rem;
}
.cid-srtCzjAFXg .table .table-wrapper .table-purchase .table-pricing {
  margin: 0;
  margin-top: .5rem !important;
}
.cid-srtCzjAFXg .table .table-wrapper .list-group {
  padding-top: 2rem;
  list-style: none;
}
.cid-srtCzjAFXg .table .table-wrapper .list-group li {
  padding: .4rem;
  border-bottom: 1px solid #f4f8fa;
}
.cid-srtCzjAFXg .table .table-wrapper .list-group li .mbr-iconfont {
  font-size: 30px;
}
@media (max-width: 767px) {
  .cid-srtCzjAFXg .first-table,
  .cid-srtCzjAFXg .second-table {
    margin: 0 15px;
  }
  .cid-srtCzjAFXg .first-table {
    margin-bottom: 1rem;
  }
}
@media (min-width: 768px) {
  .cid-srtCzjAFXg .items-row {
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .cid-srtCzjAFXg .first-table,
  .cid-srtCzjAFXg .second-table {
    max-width: calc(50% - 15px);
  }
}
@media (max-width: 991px) {
  .cid-srtCzjAFXg .main-head {
    padding-bottom: 2rem;
  }
}
.cid-srtCzjAFXg .table-heading {
  text-align: center;
  color: #ffffff;
}
.cid-srtCzjAFXg .pricing-value {
  text-align: center;
  color: #ffffff;
}
.cid-srtCzjAFXg .table-pricing {
  text-align: center;
  color: #ffffff;
}
.cid-srtjJ5hgdk {
  padding-top: 75px;
  padding-bottom: 75px;
  background-color: #c1c1c1;
}
.cid-srtjJ5hgdk .mbr-text {
  color: #767676;
}
.cid-srtjJ5hgdk .card-wrapper {
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  transition: all 0.3s;
  padding: 1rem 1rem !important;
  box-shadow: 0 10px 55px 5px rgba(87, 227, 177, 0.35);
}
.cid-srtjJ5hgdk .card-wrapper:hover {
  transform: translateY(-1rem);
}
.cid-srtjJ5hgdk .card-wrapper:hover .ico2 {
  color: #ff4f7b !important;
}
.cid-srtjJ5hgdk .text-info:hover {
  color: inherit!important;
}
.cid-srtjJ5hgdk .ico2 {
  color: #c1c1c1 !important;
  transition: all 0.6s;
}
.cid-srtjJ5hgdk .card-box {
  padding: 0rem 1rem;
}
.cid-srtjJ5hgdk .card-img {
  width: auto;
}
.cid-srtjJ5hgdk .ico1 {
  font-size: 4rem !important;
}
.cid-srtjJ5hgdk h3,
.cid-srtjJ5hgdk h4 {
  padding: 0;
  margin: 0;
}
.cid-srtjJ5hgdk .card-title,
.cid-srtjJ5hgdk .card-img {
  color: #232323;
}
.cid-srtjJ5hgdk H3 {
  color: #767676;
}
.cid-srtjJ5hgdk H1 {
  color: #232323;
}
.cid-srtGc4Rmvn.popup-builder {
  background-color: #ffffff;
}
.cid-srtGc4Rmvn.popup-builder .modal {
  position: relative;
  display: block;
  z-index: 1;
}
.cid-srtGc4Rmvn.popup-builder .modal-dialog {
  margin-top: 60px;
  margin-bottom: 60px;
}
.cid-srtGc4Rmvn .modal-content,
.cid-srtGc4Rmvn .modal-dialog {
  height: auto;
}
.cid-srtGc4Rmvn .form-wrapper .input-group-btn {
  margin-right: auto;
  margin-left: auto;
}
.cid-srtGc4Rmvn .form-wrapper .input-group-btn .btn {
  margin: 0 !important;
}
@media (min-width: 769px) {
  .cid-srtGc4Rmvn .form-wrapper .mbr-form .form-group,
  .cid-srtGc4Rmvn .form-wrapper .mbr-form .input-group-btn {
    padding: 0 .5rem;
  }
}
.cid-srtGc4Rmvn .card-img {
  width: 100%;
  margin: auto;
  border-radius: 0;
}
.cid-srtGc4Rmvn .mbr-figure img {
  display: block;
  width: 100%;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  -webkit-align-self: center;
  align-self: center;
}
.cid-srtGc4Rmvn .mbr-text {
  text-align: center;
}
.cid-srtGc4Rmvn .pt-0 {
  padding-top: 0 !important;
}
.cid-srtGc4Rmvn .pb-0 {
  padding-bottom: 0 !important;
}
.cid-srtGc4Rmvn .form-content {
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}
.cid-srtGc4Rmvn .mbr-overlay {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
  pointer-events: none;
}
.cid-srtGc4Rmvn .modal-open {
  overflow: hidden;
}
.cid-srtGc4Rmvn .modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.cid-srtGc4Rmvn .modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}
.cid-srtGc4Rmvn .modal-dialog {
  position: relative;
  width: auto;
  margin: .5rem;
  pointer-events: none;
}
.cid-srtGc4Rmvn .modal.fade .modal-dialog {
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
}
.cid-srtGc4Rmvn .modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}
.cid-srtGc4Rmvn .modal-dialog-centered {
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - (.5rem * 2));
}
.cid-srtGc4Rmvn .modal-dialog-centered::before {
  display: block;
  height: calc(100vh - (.5rem * 2));
  content: "";
}
.cid-srtGc4Rmvn .modal-content {
  background: #ffffff;
  position: relative;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-clip: padding-box;
  border: none;
  outline: 0;
  -webkit-box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
}
.cid-srtGc4Rmvn .modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.cid-srtGc4Rmvn .modal-backdrop.fade {
  opacity: 0;
}
.cid-srtGc4Rmvn .modal-backdrop.show {
  opacity: .5;
}
.cid-srtGc4Rmvn .modal-header {
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: none;
}
.cid-srtGc4Rmvn .modal-header .close {
  position: absolute;
  top: auto;
  right: 1rem;
  margin: -1rem -1rem -1rem auto;
  padding: 1rem;
  opacity: .75;
}
.cid-srtGc4Rmvn .modal-header .close:hover {
  opacity: 1;
}
.cid-srtGc4Rmvn .modal-header .close:focus {
  outline: none;
}
.cid-srtGc4Rmvn .modal-title {
  line-height: 1.5;
  width: 100%;
  margin: 0;
  text-align: center;
}
.cid-srtGc4Rmvn .modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
  min-height: 100%;
}
.cid-srtGc4Rmvn .modal-footer {
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 1rem;
  border-top: none;
  text-align: center;
}
.cid-srtGc4Rmvn .modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (min-width: 576px) {
  .cid-srtGc4Rmvn .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .cid-srtGc4Rmvn .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }
  .cid-srtGc4Rmvn .modal-dialog-centered::before {
    height: calc(100vh - (1.75rem * 2));
  }
  .cid-srtGc4Rmvn .modal-sm {
    max-width: 300px;
  }
  .cid-srtGc4Rmvn .container {
    max-width: 540px;
  }
}
@media (min-width: 992px) {
  .cid-srtGc4Rmvn .modal-lg,
  .cid-srtGc4Rmvn .modal-xl {
    max-width: 800px;
  }
  .cid-srtGc4Rmvn .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .cid-srtGc4Rmvn .modal-xl {
    max-width: 1140px;
  }
  .cid-srtGc4Rmvn .container {
    max-width: 1140px;
  }
}
.cid-srtGc4Rmvn .container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .cid-srtGc4Rmvn .container {
    max-width: 720px;
  }
}
.cid-srtGc4Rmvn .row {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.cid-srtGc4Rmvn .col-md-4 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.cid-srtGc4Rmvn .form-group {
  margin-bottom: 1rem;
}
.cid-srtGc4Rmvn .form-control {
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}
.cid-srtGc4Rmvn .col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}
.cid-srtGc4Rmvn .col-md-auto {
  position: relative;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}
.cid-srQEPHe5Ln.popup-builder {
  background-color: #ffffff;
}
.cid-srQEPHe5Ln.popup-builder .modal {
  position: relative;
  display: block;
  z-index: 1;
}
.cid-srQEPHe5Ln.popup-builder .modal-dialog {
  margin-top: 60px;
  margin-bottom: 60px;
}
.cid-srQEPHe5Ln .modal-content,
.cid-srQEPHe5Ln .modal-dialog {
  height: auto;
}
.cid-srQEPHe5Ln .form-wrapper .input-group-btn {
  margin-right: auto;
  margin-left: auto;
}
.cid-srQEPHe5Ln .form-wrapper .input-group-btn .btn {
  margin: 0 !important;
}
@media (min-width: 769px) {
  .cid-srQEPHe5Ln .form-wrapper .mbr-form .form-group,
  .cid-srQEPHe5Ln .form-wrapper .mbr-form .input-group-btn {
    padding: 0 .5rem;
  }
}
.cid-srQEPHe5Ln .card-img {
  width: 100%;
  margin: auto;
  border-radius: 0;
}
.cid-srQEPHe5Ln .mbr-figure img {
  display: block;
  width: 100%;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  -webkit-align-self: center;
  align-self: center;
}
.cid-srQEPHe5Ln .mbr-text {
  text-align: center;
}
.cid-srQEPHe5Ln .pt-0 {
  padding-top: 0 !important;
}
.cid-srQEPHe5Ln .pb-0 {
  padding-bottom: 0 !important;
}
.cid-srQEPHe5Ln .form-content {
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}
.cid-srQEPHe5Ln .mbr-overlay {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
  pointer-events: none;
}
.cid-srQEPHe5Ln .modal-open {
  overflow: hidden;
}
.cid-srQEPHe5Ln .modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.cid-srQEPHe5Ln .modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}
.cid-srQEPHe5Ln .modal-dialog {
  position: relative;
  width: auto;
  margin: .5rem;
  pointer-events: none;
}
.cid-srQEPHe5Ln .modal.fade .modal-dialog {
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
}
.cid-srQEPHe5Ln .modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}
.cid-srQEPHe5Ln .modal-dialog-centered {
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - (.5rem * 2));
}
.cid-srQEPHe5Ln .modal-dialog-centered::before {
  display: block;
  height: calc(100vh - (.5rem * 2));
  content: "";
}
.cid-srQEPHe5Ln .modal-content {
  background: #ffffff;
  position: relative;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-clip: padding-box;
  border: none;
  outline: 0;
  -webkit-box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
}
.cid-srQEPHe5Ln .modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.cid-srQEPHe5Ln .modal-backdrop.fade {
  opacity: 0;
}
.cid-srQEPHe5Ln .modal-backdrop.show {
  opacity: .5;
}
.cid-srQEPHe5Ln .modal-header {
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: none;
}
.cid-srQEPHe5Ln .modal-header .close {
  position: absolute;
  top: auto;
  right: 1rem;
  margin: -1rem -1rem -1rem auto;
  padding: 1rem;
  opacity: .75;
}
.cid-srQEPHe5Ln .modal-header .close:hover {
  opacity: 1;
}
.cid-srQEPHe5Ln .modal-header .close:focus {
  outline: none;
}
.cid-srQEPHe5Ln .modal-title {
  line-height: 1.5;
  width: 100%;
  margin: 0;
  text-align: center;
}
.cid-srQEPHe5Ln .modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
  min-height: 100%;
}
.cid-srQEPHe5Ln .modal-footer {
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 1rem;
  border-top: none;
  text-align: center;
}
.cid-srQEPHe5Ln .modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (min-width: 576px) {
  .cid-srQEPHe5Ln .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .cid-srQEPHe5Ln .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }
  .cid-srQEPHe5Ln .modal-dialog-centered::before {
    height: calc(100vh - (1.75rem * 2));
  }
  .cid-srQEPHe5Ln .modal-sm {
    max-width: 300px;
  }
  .cid-srQEPHe5Ln .container {
    max-width: 540px;
  }
}
@media (min-width: 992px) {
  .cid-srQEPHe5Ln .modal-lg,
  .cid-srQEPHe5Ln .modal-xl {
    max-width: 800px;
  }
  .cid-srQEPHe5Ln .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .cid-srQEPHe5Ln .modal-xl {
    max-width: 1140px;
  }
  .cid-srQEPHe5Ln .container {
    max-width: 1140px;
  }
}
.cid-srQEPHe5Ln .container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .cid-srQEPHe5Ln .container {
    max-width: 720px;
  }
}
.cid-srQEPHe5Ln .row {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.cid-srQEPHe5Ln .col-md-4 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.cid-srQEPHe5Ln .form-group {
  margin-bottom: 1rem;
}
.cid-srQEPHe5Ln .form-control {
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}
.cid-srQEPHe5Ln .col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}
.cid-srQEPHe5Ln .col-md-auto {
  position: relative;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}
.cid-srWP0wpUot {
  padding-top: 210px;
  padding-bottom: 105px;
  background: linear-gradient(to right, #354a5c 0%, #354a5c 30%, #082136 30%, #082136 100%);
  overflow: hidden !important;
}
.cid-srWP0wpUot .animated-element {
  color: #efefef;
}
.cid-srWP0wpUot .img-block {
  align-self: center;
}
.cid-srWP0wpUot .mbr-section-subtitle {
  color: #efefef;
}
@media (min-width: 992px) {
  .cid-srWP0wpUot .img-block {
    padding-right: 2rem;
  }
}
@media (max-width: 767px) {
  .cid-srWP0wpUot .mbr-figure {
    margin-top: 1rem;
  }
}
@media (max-width: 768px) {
  .cid-srWP0wpUot {
    background: #082136;
  }
  .cid-srWP0wpUot .img-block {
    padding-bottom: 2rem;
  }
}
.cid-srWP0wpUot .mbr-section-title {
  text-align: center;
}
.cid-srWP0wpUot .mbr-text,
.cid-srWP0wpUot .mbr-section-btn {
  text-align: center;
}
.cid-srQri3u1JP {
  padding-top: 40px;
  padding-bottom: 60px;
  background: #a4b8c9;
}
.cid-srQri3u1JP .underline .line {
  width: 2rem;
  height: 1px;
  background: #ffffff;
  display: inline-block;
}
.cid-srQri3u1JP .main-head {
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  justify-content: center;
}
.cid-srQri3u1JP .first-table {
  margin: 0;
}
.cid-srQri3u1JP .first-table.two-items {
  margin-right: 15px;
}
.cid-srQri3u1JP .first-table .table-head-wrapper {
  background: #08579f;
}
.cid-srQri3u1JP .second-table {
  margin: 0;
  margin-left: 15px;
}
.cid-srQri3u1JP .second-table .table-head-wrapper {
  background: #098484;
}
.cid-srQri3u1JP .table {
  padding: 2rem;
  background: #ffffff;
  position: relative;
}
.cid-srQri3u1JP .table .table-wrapper .table-head-wrapper {
  margin: -2rem;
  margin-bottom: 0 !important;
  padding: 2rem;
  padding-bottom: 0 !important;
}
.cid-srQri3u1JP .table .table-wrapper .table-head-wrapper span {
  width: 100%;
  display: block;
}
.cid-srQri3u1JP .table .table-wrapper .table-purchase {
  padding-bottom: 2rem;
}
.cid-srQri3u1JP .table .table-wrapper .table-purchase .table-pricing {
  margin: 0;
  margin-top: .5rem !important;
}
.cid-srQri3u1JP .table .table-wrapper .list-group {
  padding-top: 2rem;
  list-style: none;
}
.cid-srQri3u1JP .table .table-wrapper .list-group li {
  padding: .4rem;
  border-bottom: 1px solid #f4f8fa;
}
.cid-srQri3u1JP .table .table-wrapper .list-group li .mbr-iconfont {
  font-size: 30px;
}
@media (max-width: 767px) {
  .cid-srQri3u1JP .first-table,
  .cid-srQri3u1JP .second-table {
    margin: 0 15px;
  }
  .cid-srQri3u1JP .first-table {
    margin-bottom: 1rem;
  }
}
@media (min-width: 768px) {
  .cid-srQri3u1JP .items-row {
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .cid-srQri3u1JP .first-table,
  .cid-srQri3u1JP .second-table {
    max-width: calc(50% - 15px);
  }
}
@media (max-width: 991px) {
  .cid-srQri3u1JP .main-head {
    padding-bottom: 2rem;
  }
}
.cid-srQri3u1JP .table-heading {
  text-align: center;
  color: #ffffff;
}
.cid-srQri3u1JP .pricing-value {
  text-align: center;
  color: #ffffff;
}
.cid-srQri3u1JP .table-pricing {
  text-align: center;
  color: #ffffff;
}
.cid-srQri3u1JP .main-title {
  color: #000000;
}
.cid-srQrieJkEn.popup-builder {
  background-color: #ffffff;
}
.cid-srQrieJkEn.popup-builder .modal {
  position: relative;
  display: block;
  z-index: 1;
}
.cid-srQrieJkEn.popup-builder .modal-dialog {
  margin-top: 60px;
  margin-bottom: 60px;
}
.cid-srQrieJkEn .modal-content,
.cid-srQrieJkEn .modal-dialog {
  height: auto;
}
.cid-srQrieJkEn .form-wrapper .input-group-btn {
  margin-right: auto;
  margin-left: auto;
}
.cid-srQrieJkEn .form-wrapper .input-group-btn .btn {
  margin: 0 !important;
}
@media (min-width: 769px) {
  .cid-srQrieJkEn .form-wrapper .mbr-form .form-group,
  .cid-srQrieJkEn .form-wrapper .mbr-form .input-group-btn {
    padding: 0 .5rem;
  }
}
.cid-srQrieJkEn .card-img {
  width: 100%;
  margin: auto;
  border-radius: 0;
}
.cid-srQrieJkEn .mbr-figure img {
  display: block;
  width: 100%;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  -webkit-align-self: center;
  align-self: center;
}
.cid-srQrieJkEn .mbr-text {
  text-align: center;
}
.cid-srQrieJkEn .pt-0 {
  padding-top: 0 !important;
}
.cid-srQrieJkEn .pb-0 {
  padding-bottom: 0 !important;
}
.cid-srQrieJkEn .form-content {
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}
.cid-srQrieJkEn .mbr-overlay {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
  pointer-events: none;
}
.cid-srQrieJkEn .modal-open {
  overflow: hidden;
}
.cid-srQrieJkEn .modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.cid-srQrieJkEn .modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}
.cid-srQrieJkEn .modal-dialog {
  position: relative;
  width: auto;
  margin: .5rem;
  pointer-events: none;
}
.cid-srQrieJkEn .modal.fade .modal-dialog {
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
}
.cid-srQrieJkEn .modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}
.cid-srQrieJkEn .modal-dialog-centered {
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - (.5rem * 2));
}
.cid-srQrieJkEn .modal-dialog-centered::before {
  display: block;
  height: calc(100vh - (.5rem * 2));
  content: "";
}
.cid-srQrieJkEn .modal-content {
  background: #ffffff;
  position: relative;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-clip: padding-box;
  border: none;
  outline: 0;
  -webkit-box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
}
.cid-srQrieJkEn .modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.cid-srQrieJkEn .modal-backdrop.fade {
  opacity: 0;
}
.cid-srQrieJkEn .modal-backdrop.show {
  opacity: .5;
}
.cid-srQrieJkEn .modal-header {
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: none;
}
.cid-srQrieJkEn .modal-header .close {
  position: absolute;
  top: auto;
  right: 1rem;
  margin: -1rem -1rem -1rem auto;
  padding: 1rem;
  opacity: .75;
}
.cid-srQrieJkEn .modal-header .close:hover {
  opacity: 1;
}
.cid-srQrieJkEn .modal-header .close:focus {
  outline: none;
}
.cid-srQrieJkEn .modal-title {
  line-height: 1.5;
  width: 100%;
  margin: 0;
  text-align: center;
}
.cid-srQrieJkEn .modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
  min-height: 100%;
}
.cid-srQrieJkEn .modal-footer {
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 1rem;
  border-top: none;
  text-align: center;
}
.cid-srQrieJkEn .modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (min-width: 576px) {
  .cid-srQrieJkEn .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .cid-srQrieJkEn .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }
  .cid-srQrieJkEn .modal-dialog-centered::before {
    height: calc(100vh - (1.75rem * 2));
  }
  .cid-srQrieJkEn .modal-sm {
    max-width: 300px;
  }
  .cid-srQrieJkEn .container {
    max-width: 540px;
  }
}
@media (min-width: 992px) {
  .cid-srQrieJkEn .modal-lg,
  .cid-srQrieJkEn .modal-xl {
    max-width: 800px;
  }
  .cid-srQrieJkEn .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .cid-srQrieJkEn .modal-xl {
    max-width: 1140px;
  }
  .cid-srQrieJkEn .container {
    max-width: 1140px;
  }
}
.cid-srQrieJkEn .container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .cid-srQrieJkEn .container {
    max-width: 720px;
  }
}
.cid-srQrieJkEn .row {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.cid-srQrieJkEn .col-md-4 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.cid-srQrieJkEn .form-group {
  margin-bottom: 1rem;
}
.cid-srQrieJkEn .form-control {
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}
.cid-srQrieJkEn .col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}
.cid-srQrieJkEn .col-md-auto {
  position: relative;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}
.cid-srQEPHhYQX.popup-builder {
  background-color: #ffffff;
}
.cid-srQEPHhYQX.popup-builder .modal {
  position: relative;
  display: block;
  z-index: 1;
}
.cid-srQEPHhYQX.popup-builder .modal-dialog {
  margin-top: 60px;
  margin-bottom: 60px;
}
.cid-srQEPHhYQX .modal-content,
.cid-srQEPHhYQX .modal-dialog {
  height: auto;
}
.cid-srQEPHhYQX .form-wrapper .input-group-btn {
  margin-right: auto;
  margin-left: auto;
}
.cid-srQEPHhYQX .form-wrapper .input-group-btn .btn {
  margin: 0 !important;
}
@media (min-width: 769px) {
  .cid-srQEPHhYQX .form-wrapper .mbr-form .form-group,
  .cid-srQEPHhYQX .form-wrapper .mbr-form .input-group-btn {
    padding: 0 .5rem;
  }
}
.cid-srQEPHhYQX .card-img {
  width: 100%;
  margin: auto;
  border-radius: 0;
}
.cid-srQEPHhYQX .mbr-figure img {
  display: block;
  width: 100%;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  -webkit-align-self: center;
  align-self: center;
}
.cid-srQEPHhYQX .mbr-text {
  text-align: center;
}
.cid-srQEPHhYQX .pt-0 {
  padding-top: 0 !important;
}
.cid-srQEPHhYQX .pb-0 {
  padding-bottom: 0 !important;
}
.cid-srQEPHhYQX .form-content {
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}
.cid-srQEPHhYQX .mbr-overlay {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
  pointer-events: none;
}
.cid-srQEPHhYQX .modal-open {
  overflow: hidden;
}
.cid-srQEPHhYQX .modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.cid-srQEPHhYQX .modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}
.cid-srQEPHhYQX .modal-dialog {
  position: relative;
  width: auto;
  margin: .5rem;
  pointer-events: none;
}
.cid-srQEPHhYQX .modal.fade .modal-dialog {
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
}
.cid-srQEPHhYQX .modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}
.cid-srQEPHhYQX .modal-dialog-centered {
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - (.5rem * 2));
}
.cid-srQEPHhYQX .modal-dialog-centered::before {
  display: block;
  height: calc(100vh - (.5rem * 2));
  content: "";
}
.cid-srQEPHhYQX .modal-content {
  background: #ffffff;
  position: relative;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-clip: padding-box;
  border: none;
  outline: 0;
  -webkit-box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
}
.cid-srQEPHhYQX .modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.cid-srQEPHhYQX .modal-backdrop.fade {
  opacity: 0;
}
.cid-srQEPHhYQX .modal-backdrop.show {
  opacity: .5;
}
.cid-srQEPHhYQX .modal-header {
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: none;
}
.cid-srQEPHhYQX .modal-header .close {
  position: absolute;
  top: auto;
  right: 1rem;
  margin: -1rem -1rem -1rem auto;
  padding: 1rem;
  opacity: .75;
}
.cid-srQEPHhYQX .modal-header .close:hover {
  opacity: 1;
}
.cid-srQEPHhYQX .modal-header .close:focus {
  outline: none;
}
.cid-srQEPHhYQX .modal-title {
  line-height: 1.5;
  width: 100%;
  margin: 0;
  text-align: center;
}
.cid-srQEPHhYQX .modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
  min-height: 100%;
}
.cid-srQEPHhYQX .modal-footer {
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 1rem;
  border-top: none;
  text-align: center;
}
.cid-srQEPHhYQX .modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (min-width: 576px) {
  .cid-srQEPHhYQX .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .cid-srQEPHhYQX .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }
  .cid-srQEPHhYQX .modal-dialog-centered::before {
    height: calc(100vh - (1.75rem * 2));
  }
  .cid-srQEPHhYQX .modal-sm {
    max-width: 300px;
  }
  .cid-srQEPHhYQX .container {
    max-width: 540px;
  }
}
@media (min-width: 992px) {
  .cid-srQEPHhYQX .modal-lg,
  .cid-srQEPHhYQX .modal-xl {
    max-width: 800px;
  }
  .cid-srQEPHhYQX .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .cid-srQEPHhYQX .modal-xl {
    max-width: 1140px;
  }
  .cid-srQEPHhYQX .container {
    max-width: 1140px;
  }
}
.cid-srQEPHhYQX .container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .cid-srQEPHhYQX .container {
    max-width: 720px;
  }
}
.cid-srQEPHhYQX .row {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.cid-srQEPHhYQX .col-md-4 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.cid-srQEPHhYQX .form-group {
  margin-bottom: 1rem;
}
.cid-srQEPHhYQX .form-control {
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}
.cid-srQEPHhYQX .col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}
.cid-srQEPHhYQX .col-md-auto {
  position: relative;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}
.cid-srQwy0nytu {
  padding-top: 200px;
  padding-bottom: 90px;
  background: #ffffff;
  background: linear-gradient(90deg, #ffffff, #082136);
}
.cid-srQwy0nytu .container-fluid {
  padding: 0 3rem;
}
.cid-srQwy0nytu .table-wrap {
  overflow: hidden;
  border-radius: 3%;
  background-color: #efefef;
  transition: 0.3s ease-out all;
  padding: 0;
  margin-bottom: 1em;
}
.cid-srQwy0nytu .table-wrap .table-pricing {
  margin: 0 0 0.4em 0;
}
.cid-srQwy0nytu .table-wrap ul {
  word-wrap: break-word;
  padding: 0 4rem;
  margin: 0 0 2rem 0;
  list-style: none;
  margin: 0;
}
.cid-srQwy0nytu .table-wrap ul li {
  padding: 0.7em 0;
}
.cid-srQwy0nytu .table-wrap ul li span {
  font-size: 32px;
  color: #000000;
  vertical-align: bottom;
  display: inline-block;
}
.cid-srQwy0nytu .table-wrap ul:last-child {
  padding-bottom: 2rem;
}
.cid-srQwy0nytu .table-wrap:hover {
  box-shadow: 0 7px 24px 0 rgba(0, 0, 0, 0.42);
}
.cid-srQwy0nytu .table-wrap:hover .table-heading {
  background-color: #a38dfd;
}
.cid-srQwy0nytu .mbr-iconfont {
  margin: 0 0.5em;
}
.cid-srQwy0nytu .table-heading {
  transition: 0.3s ease-out all;
  display: block;
  width: 100%;
  padding: 0.6em 4rem;
  background-color: #082136;
  color: #ffffff;
  text-align: center;
}
.cid-srQwy0nytu .table-purchase {
  padding: 0 4rem;
  margin: 2.5em 0 0 0;
}
.cid-srQwy0nytu .table-purchase .pricing-value {
  vertical-align: top;
  display: inline-block;
}
.cid-srQwy0nytu .table-purchase .table-pricing {
  display: inline-block;
}
.cid-srQwy0nytu .table-pricing,
.cid-srQwy0nytu .pricing-value h1 {
  text-align: left;
}
.cid-srQwy0nytu .table-pricing {
  text-align: center;
}
@media (max-width: 767px) {
  .cid-srQwy0nytu .container-fluid {
    padding: 0 1rem;
  }
}
@media (max-width: 992px) {
  .cid-srQwy0nytu {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }
}
@media (max-width: 1393px) {
  .cid-srQwy0nytu ul {
    padding: 1rem !important;
  }
  .cid-srQwy0nytu .table-purchase {
    padding: 1rem !important;
  }
  .cid-srQwy0nytu .table-heading {
    padding: 1rem !important;
  }
}
.cid-srQw9wXq1s.popup-builder {
  background-color: #ffffff;
}
.cid-srQw9wXq1s.popup-builder .modal {
  position: relative;
  display: block;
  z-index: 1;
}
.cid-srQw9wXq1s.popup-builder .modal-dialog {
  margin-top: 60px;
  margin-bottom: 60px;
}
.cid-srQw9wXq1s .modal-content,
.cid-srQw9wXq1s .modal-dialog {
  height: auto;
}
.cid-srQw9wXq1s .form-wrapper .input-group-btn {
  margin-right: auto;
  margin-left: auto;
}
.cid-srQw9wXq1s .form-wrapper .input-group-btn .btn {
  margin: 0 !important;
}
@media (min-width: 769px) {
  .cid-srQw9wXq1s .form-wrapper .mbr-form .form-group,
  .cid-srQw9wXq1s .form-wrapper .mbr-form .input-group-btn {
    padding: 0 .5rem;
  }
}
.cid-srQw9wXq1s .card-img {
  width: 100%;
  margin: auto;
  border-radius: 0;
}
.cid-srQw9wXq1s .mbr-figure img {
  display: block;
  width: 100%;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  -webkit-align-self: center;
  align-self: center;
}
.cid-srQw9wXq1s .mbr-text {
  text-align: center;
}
.cid-srQw9wXq1s .pt-0 {
  padding-top: 0 !important;
}
.cid-srQw9wXq1s .pb-0 {
  padding-bottom: 0 !important;
}
.cid-srQw9wXq1s .form-content {
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}
.cid-srQw9wXq1s .mbr-overlay {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
  pointer-events: none;
}
.cid-srQw9wXq1s .modal-open {
  overflow: hidden;
}
.cid-srQw9wXq1s .modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.cid-srQw9wXq1s .modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}
.cid-srQw9wXq1s .modal-dialog {
  position: relative;
  width: auto;
  margin: .5rem;
  pointer-events: none;
}
.cid-srQw9wXq1s .modal.fade .modal-dialog {
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
}
.cid-srQw9wXq1s .modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}
.cid-srQw9wXq1s .modal-dialog-centered {
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - (.5rem * 2));
}
.cid-srQw9wXq1s .modal-dialog-centered::before {
  display: block;
  height: calc(100vh - (.5rem * 2));
  content: "";
}
.cid-srQw9wXq1s .modal-content {
  background: #ffffff;
  position: relative;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-clip: padding-box;
  border: none;
  outline: 0;
  -webkit-box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
}
.cid-srQw9wXq1s .modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.cid-srQw9wXq1s .modal-backdrop.fade {
  opacity: 0;
}
.cid-srQw9wXq1s .modal-backdrop.show {
  opacity: .5;
}
.cid-srQw9wXq1s .modal-header {
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: none;
}
.cid-srQw9wXq1s .modal-header .close {
  position: absolute;
  top: auto;
  right: 1rem;
  margin: -1rem -1rem -1rem auto;
  padding: 1rem;
  opacity: .75;
}
.cid-srQw9wXq1s .modal-header .close:hover {
  opacity: 1;
}
.cid-srQw9wXq1s .modal-header .close:focus {
  outline: none;
}
.cid-srQw9wXq1s .modal-title {
  line-height: 1.5;
  width: 100%;
  margin: 0;
  text-align: center;
}
.cid-srQw9wXq1s .modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
  min-height: 100%;
}
.cid-srQw9wXq1s .modal-footer {
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 1rem;
  border-top: none;
  text-align: center;
}
.cid-srQw9wXq1s .modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (min-width: 576px) {
  .cid-srQw9wXq1s .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .cid-srQw9wXq1s .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }
  .cid-srQw9wXq1s .modal-dialog-centered::before {
    height: calc(100vh - (1.75rem * 2));
  }
  .cid-srQw9wXq1s .modal-sm {
    max-width: 300px;
  }
  .cid-srQw9wXq1s .container {
    max-width: 540px;
  }
}
@media (min-width: 992px) {
  .cid-srQw9wXq1s .modal-lg,
  .cid-srQw9wXq1s .modal-xl {
    max-width: 800px;
  }
  .cid-srQw9wXq1s .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .cid-srQw9wXq1s .modal-xl {
    max-width: 1140px;
  }
  .cid-srQw9wXq1s .container {
    max-width: 1140px;
  }
}
.cid-srQw9wXq1s .container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .cid-srQw9wXq1s .container {
    max-width: 720px;
  }
}
.cid-srQw9wXq1s .row {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.cid-srQw9wXq1s .col-md-4 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.cid-srQw9wXq1s .form-group {
  margin-bottom: 1rem;
}
.cid-srQw9wXq1s .form-control {
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}
.cid-srQw9wXq1s .col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}
.cid-srQw9wXq1s .col-md-auto {
  position: relative;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}
.cid-srQEPHiPQP.popup-builder {
  background-color: #ffffff;
}
.cid-srQEPHiPQP.popup-builder .modal {
  position: relative;
  display: block;
  z-index: 1;
}
.cid-srQEPHiPQP.popup-builder .modal-dialog {
  margin-top: 60px;
  margin-bottom: 60px;
}
.cid-srQEPHiPQP .modal-content,
.cid-srQEPHiPQP .modal-dialog {
  height: auto;
}
.cid-srQEPHiPQP .form-wrapper .input-group-btn {
  margin-right: auto;
  margin-left: auto;
}
.cid-srQEPHiPQP .form-wrapper .input-group-btn .btn {
  margin: 0 !important;
}
@media (min-width: 769px) {
  .cid-srQEPHiPQP .form-wrapper .mbr-form .form-group,
  .cid-srQEPHiPQP .form-wrapper .mbr-form .input-group-btn {
    padding: 0 .5rem;
  }
}
.cid-srQEPHiPQP .card-img {
  width: 100%;
  margin: auto;
  border-radius: 0;
}
.cid-srQEPHiPQP .mbr-figure img {
  display: block;
  width: 100%;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  -webkit-align-self: center;
  align-self: center;
}
.cid-srQEPHiPQP .mbr-text {
  text-align: center;
}
.cid-srQEPHiPQP .pt-0 {
  padding-top: 0 !important;
}
.cid-srQEPHiPQP .pb-0 {
  padding-bottom: 0 !important;
}
.cid-srQEPHiPQP .form-content {
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}
.cid-srQEPHiPQP .mbr-overlay {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
  pointer-events: none;
}
.cid-srQEPHiPQP .modal-open {
  overflow: hidden;
}
.cid-srQEPHiPQP .modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.cid-srQEPHiPQP .modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}
.cid-srQEPHiPQP .modal-dialog {
  position: relative;
  width: auto;
  margin: .5rem;
  pointer-events: none;
}
.cid-srQEPHiPQP .modal.fade .modal-dialog {
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
}
.cid-srQEPHiPQP .modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}
.cid-srQEPHiPQP .modal-dialog-centered {
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - (.5rem * 2));
}
.cid-srQEPHiPQP .modal-dialog-centered::before {
  display: block;
  height: calc(100vh - (.5rem * 2));
  content: "";
}
.cid-srQEPHiPQP .modal-content {
  background: #ffffff;
  position: relative;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-clip: padding-box;
  border: none;
  outline: 0;
  -webkit-box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
}
.cid-srQEPHiPQP .modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.cid-srQEPHiPQP .modal-backdrop.fade {
  opacity: 0;
}
.cid-srQEPHiPQP .modal-backdrop.show {
  opacity: .5;
}
.cid-srQEPHiPQP .modal-header {
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: none;
}
.cid-srQEPHiPQP .modal-header .close {
  position: absolute;
  top: auto;
  right: 1rem;
  margin: -1rem -1rem -1rem auto;
  padding: 1rem;
  opacity: .75;
}
.cid-srQEPHiPQP .modal-header .close:hover {
  opacity: 1;
}
.cid-srQEPHiPQP .modal-header .close:focus {
  outline: none;
}
.cid-srQEPHiPQP .modal-title {
  line-height: 1.5;
  width: 100%;
  margin: 0;
  text-align: center;
}
.cid-srQEPHiPQP .modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
  min-height: 100%;
}
.cid-srQEPHiPQP .modal-footer {
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 1rem;
  border-top: none;
  text-align: center;
}
.cid-srQEPHiPQP .modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (min-width: 576px) {
  .cid-srQEPHiPQP .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .cid-srQEPHiPQP .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }
  .cid-srQEPHiPQP .modal-dialog-centered::before {
    height: calc(100vh - (1.75rem * 2));
  }
  .cid-srQEPHiPQP .modal-sm {
    max-width: 300px;
  }
  .cid-srQEPHiPQP .container {
    max-width: 540px;
  }
}
@media (min-width: 992px) {
  .cid-srQEPHiPQP .modal-lg,
  .cid-srQEPHiPQP .modal-xl {
    max-width: 800px;
  }
  .cid-srQEPHiPQP .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .cid-srQEPHiPQP .modal-xl {
    max-width: 1140px;
  }
  .cid-srQEPHiPQP .container {
    max-width: 1140px;
  }
}
.cid-srQEPHiPQP .container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .cid-srQEPHiPQP .container {
    max-width: 720px;
  }
}
.cid-srQEPHiPQP .row {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.cid-srQEPHiPQP .col-md-4 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.cid-srQEPHiPQP .form-group {
  margin-bottom: 1rem;
}
.cid-srQEPHiPQP .form-control {
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}
.cid-srQEPHiPQP .col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}
.cid-srQEPHiPQP .col-md-auto {
  position: relative;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}
